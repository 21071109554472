import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orders: []
};

export const ordersSlice = createSlice({
    name: 'ordersSlice',
    initialState,
    reducers: {
        // 1- Orders Reducers
        // a- getting get orders
        getAllOrders: (state, action) => {
            state.orders = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { getAllOrders } = ordersSlice.actions;

export default ordersSlice.reducer;
