import {useState} from 'react'
import { useAuthContext } from './adminAuthHook'
import { getAllTrials } from '../redux/reducers/trialSlice'
import { useDispatch } from 'react-redux';
export default function TrialHook() {
    const dispatch = useDispatch()
    const apiUrl = process.env.REACT_APP_API_URL;
    const { admin } = useAuthContext()
    const [loading, setLoading] = useState(null)
    // Fetching Trial
    const getTrials=async()=>{
      setLoading(true)
       try {
        const response=await fetch(`${apiUrl}/auth/admins/trials/get`,{
          headers:{
            'Authorization': `Bearer ${admin.token}`
            
          }
        })
        
        const json=await response.json()
        if(response.ok){
          setLoading(null)
          dispatch(getAllTrials(json.data))
        }
        if(!response.ok){
            setLoading(null)
        }
       } catch (error) {
       console.log(error)
       setLoading(null)
       }
    }

  return {getTrials,loading}
}
