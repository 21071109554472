import React,{useState,useEffect} from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow,Dialog } from '@mui/material'
import { useSelector } from 'react-redux';
import PaymentMethodsHook from '../hooks/PaymentMethodsHook';
import { useAuthContext } from '../hooks/adminAuthHook';

export default function PaymentMethods() {
  const { admin } = useAuthContext();
  const apiUrl = process.env.REACT_APP_API_URL;
  const paymentMethods = useSelector((state) => state.paymentMethods.paymentMethods);
  const { getPaymentMethods } = PaymentMethodsHook(); // Assuming updateTrial is a function in TrialsHook
  useEffect(() => {
    getPaymentMethods()
  }, [admin]);

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Activate/Inactivate state
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (newPaymentMethod) => {
    setOpen(true);
    setSelectedPaymentMethod(newPaymentMethod)

  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSelectedPaymentMethod('')
    }, 1000);
  }
   // PaymentMethod Edition
   const [editedPaymentMethod,setEditedPaymentMethod]=useState(null)
   // Adding a new PaymentMethod
 
   const [account_Holder, setAccount_Holder] = useState("");
   const [payment_Gateway, setPayment_Gateway] = useState("");
   const [acc_No, setAcc_No] = useState("");
   const [iban_No, setIbanNo] = useState('');
   const [isActive, setIsActive] = useState();
   const [logo, setLogo] = useState(''); 
   const [openNew, setOpenNew] = React.useState(false);
   const handleClickOpenNew = () => {
     setOpenNew(true);
   };
 
   const handleCloseNew = () => {
     setOpenNew(false);
     setAccount_Holder('')
     setPayment_Gateway('')
     setAcc_No('')
     setIbanNo('')
     setLogo('')
     setIsActive('')
     setEditedPaymentMethod('')
   }
 
 const handleEditView=(data)=>{
   setEditedPaymentMethod(data);
   setOpenNew(true);
   setAccount_Holder(data.account_Holder || "");
   setPayment_Gateway(data.payment_Gateway || "");
   setAcc_No(data.acc_No || "");
   setIbanNo(data.iban_No || "");
   setLogo(data.logo || "");
   setIsActive(data.isActive);
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    TransformFile(file)
    if (file) {
        if (file.size > 5 * 1024 * 1024) {
            alert('File size exceeds the 5MB limit. Please select a smaller file.');
        } else {
            TransformFile(file);
        }
    }
};

const TransformFile = (file) => {
    const reader = new FileReader();
    if (file) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setLogo(reader.result);
        };
    } else {
      setLogo('');
    }
}

    // Handle Select Input for Boolean Values
    const handleSelectChange = (setter) => (e) => {
      const value = e.target.value === "true" ? true : false;
      setter(value);
    };

   // API Call to add a new paymentMethod
   const [loading1, setLoading1] = useState(false)
   const addNewPaymentMethod = async (e) => {
     e.preventDefault()
     setLoading1(true)
     try {
       const response = await fetch(`${apiUrl}/auth/admins/payment_methods/add`, {
         method: "POST",
         headers: {
           'Authorization': `Bearer ${admin.token}`,
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({account_Holder,payment_Gateway,acc_No,iban_No,isActive,logo})
       })
 
       const json = await response.json()
       if (response.ok) {
         alert(json.message)
         getPaymentMethods()
         setOpen(false);
         setLoading1(null)
         handleCloseNew()
       }
       if (!response.ok) {
         alert(json.message)
         setLoading1(null)
       }
     } catch (error) {
       setLoading1(null)
     }
   }
 
   const updatePaymentMethod = async (e) => {
     e.preventDefault()
     setLoading1(true)
     try {
       const response = await fetch(`${apiUrl}/auth/admins/payment_methods/update`, {
         method: "PUT",
         headers: {
           'Authorization': `Bearer ${admin.token}`,
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({paymentMethod:{_id:editedPaymentMethod._id,account_Holder,payment_Gateway,acc_No,iban_No,isActive,logo}})
       })
 
       const json = await response.json()
       if (response.ok) {
         alert(json.message)
         getPaymentMethods()
         setOpen(false);
         setLoading1(null)
         handleCloseNew()
 
       }
       if (!response.ok) {
         alert(json.message)
         setLoading1(null)
       }
     } catch (error) {
       setLoading1(null)
     }
   }
   const [search, setSearch] = useState('');
   const filteredPaymentMethods = paymentMethods && paymentMethods
     .filter(newPaymentMethod => {
       const matchesSearch = (
         newPaymentMethod.account_Holder?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
         newPaymentMethod.payment_Gateway?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
         newPaymentMethod.acc_No?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
         newPaymentMethod.iban_No?.trim().toLowerCase().includes(search.trim().toLowerCase())
       );
 
       return matchesSearch
     })
     .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
     
     const [viewSlip,setViewSlip]=useState('')
     const [viewModal,setViewModal]=useState(false)
     const handleViewSlip=(slip)=>{
       setViewSlip(slip)
       setViewModal(true)
     }
     const handleCloseViewSlip=(slip)=>{
       setViewSlip('')
       setViewModal(false)
     }
     
   // Activating/Inactivating shops
  const [updateLoading, setUpdateLoading] = useState(false)
  const updatePaymentMethodStatus = async () => {
    setUpdateLoading(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/payment_methods/update/status`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ paymentMethodId: selectedPaymentMethod && selectedPaymentMethod._id })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        getPaymentMethods()
        setOpen(false);
        setUpdateLoading(null)

      }
      if (!response.ok) {
        alert(json.message)
        setUpdateLoading(null)
      }
    } catch (error) {
      setUpdateLoading(null)
    }
  }
  return (
    <div className='row px-2'>
 <div className="col-md-12 data_table p-2 pb-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                      <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                        <h6>All Payment Methods</h6>
                      </div>
                      <div className="d-flex right my-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                        <button className='add_btn btn ms-1' onClick={() => handleClickOpenNew()}>Add</button>
                      </div>
                    </div>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                        <TableHead>
                          <TableRow>
                            <TableCell className='th text-center'>SN</TableCell>
                            <TableCell className='th text-center'>Account Holder </TableCell>
                            <TableCell className='th text-center'>Payment Gateway</TableCell>
                            <TableCell className='th text-center'>ACC No</TableCell>
                            <TableCell className='th text-center'>IBAN No</TableCell>
                            <TableCell className='th text-center'>Status</TableCell>
                            <TableCell className='th text-center'>Logo</TableCell>
                            <TableCell className='th text-center'>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredPaymentMethods && filteredPaymentMethods.length > 0 ? filteredPaymentMethods
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((newPaymentMethod, index) => (
                              <TableRow key={newPaymentMethod._id}>
                                <TableCell className='text-center td'>{index + 1}</TableCell>
                                <TableCell className='text-center td'>{newPaymentMethod.account_Holder}</TableCell>
                                <TableCell className='text-center td'>{newPaymentMethod.payment_Gateway}</TableCell>
                                <TableCell className='text-center td'>{newPaymentMethod.acc_No}</TableCell>
                                <TableCell className='text-center td'>{newPaymentMethod.iban_No}</TableCell>
                                <TableCell className='td text-center'>{newPaymentMethod.isActive ? <span className="active_span px-2 py-1">Active</span> : <span className="inactive_span px-2 py-1">Inactive</span>}</TableCell>
                                <TableCell className='td text-center'>{newPaymentMethod.logo ?<img onClick={()=>handleViewSlip(newPaymentMethod.logo)} src={newPaymentMethod.logo} alt='Receipt' className='rounded' />: "No Receipt"}</TableCell>
                                <TableCell className='td text-center'>
                                  <div class="btn-group" role="group" aria-label="Basic example">

                                    <button className="edit_btn py-1 px-2  m-1" onClick={()=>handleEditView(newPaymentMethod)}>Edit</button>
                                    <button onClick={() => handleClickOpen(newPaymentMethod)} className={newPaymentMethod.isActive ? "inactivate_shops py-1 px-2 m-1" : "activate_shops py-1 px-2 m-1"}>{newPaymentMethod.isActive ? "Inactivate" : "Activate"}</button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )) :
                            <TableRow className='border-0'>
                              <TableCell colSpan={3}></TableCell>
                              <TableCell className='td text-center'>No result</TableCell>
                              <TableCell colSpan={4}></TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions}
                      component='div'
                      count={filteredPaymentMethods && filteredPaymentMethods.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      style={{
                        color: '#5932EA',
                        fontSize: '12px',
                        fontWeight: '700',
                        textTransform: 'capitalize',
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                   {/* View Payment Method logo Modal */}
      <Dialog
  className="shops_modal p-0"
  open={viewModal}
  onClose={handleCloseViewSlip}
  keepMounted
  aria-describedby="alert-dialog-slide-description"
  
>
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 text-center pb-2 px-0 m-0">
        <div className="image-container">
          {viewSlip && <img src={viewSlip} alt="View Slip" className="slip-image" />}
        </div>
      </div>
    </div>
  </div>
</Dialog>


 {/* adding a new Package modal */}

 <Dialog className='p-0'
        open={openNew}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12  pb-2 px-0 m-0">
              <form onSubmit={editedPaymentMethod?updatePaymentMethod:addNewPaymentMethod}>
                <div className="card add_new_package_card">
                  <div className="card-header">
                    {editedPaymentMethod ? "Edit Payment Method" : "Add a new Payment Method"}
                    </div>
                  <div className="card-body">
                  
                    <div className="image-container text-center data my-1">
                        <div className="image-wrapper">
                            <img
                                src={logo ? logo : "https://via.placeholder.com/100"}
                                alt="team-member"
                                className="profile-img"
                                onClick={() => document.getElementById('fileInput').click()}
                            />
                            <span className="add-icon" onClick={() => document.getElementById('fileInput').click()}>
                                +
                            </span>
                        </div>
                        <input
                            type="file"
                            accept='image/*'
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleImage}
                        />
                    </div>
                    <div className="data my-1">
                      <label htmlFor="" >Account holder:</label>
                      <input type="text" value={account_Holder} onChange={(e) => setAccount_Holder(e.target.value)} className='mt-1' id="" required />
                    </div>
                  
                    <div className="data my-1">
                      <label htmlFor="">Payment gateway:</label>
                      <input type="text" className='mt-1' id="" value={payment_Gateway} onChange={(e) => setPayment_Gateway(e.target.value)} required/>
                    </div>
                    <div className="data my-1">
                      <label htmlFor="">Account number</label>
                      <input type="text" value={acc_No} onChange={(e) => setAcc_No(e.target.value)} className='mt-1' id="" required />
                    </div>
                    <div className="data my-1">
                      <label htmlFor="">IBAN number:</label>
                      <input type="text" className='mt-1' id="" required value={iban_No} onChange={(e) => setIbanNo(e.target.value)} />
                    </div>
                  <div className="data my-1">
                    <label htmlFor="is-active">Activation:</label>
                    <select
                      id="is-active"
                      required
                      value={isActive}
                      onChange={handleSelectChange(setIsActive)}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                    <div className="text-end mt-3">
                      <button className="btn cancel_btn mx-1" type='button' onClick={() => handleCloseNew()} disabled={loading1}>Cancel</button>
                      <button className="btn save_btn mx-1" type='submit' disabled={loading1}>{loading1 ? "Saving" : "Save"}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>

        {/* Activate/Inactivate Modal */}
        <Dialog className='shops_modal p-0'
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="alert_icon text-center">
                <div className={selectedPaymentMethod && !selectedPaymentMethod.isActive ? "activate_icon  py-2" : "inactivate_icon  py-2"}>
                  {selectedPaymentMethod && !selectedPaymentMethod.isActive ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 ">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  }
                </div>
                <div className="content px-4">
                  <h6 className='mt-3'>Alert !</h6>
                  <p>Do you want to {selectedPaymentMethod && !selectedPaymentMethod.isActive ? "Activate" : "Inactivate"} {selectedPaymentMethod && selectedPaymentMethod.payment_Gateway} Payment Method?</p>
                </div>
              </div>
              <button className="cancel m-1 py-1 px-2" disabled={updateLoading} onClick={handleClose}>Cancel</button>
              <button className={selectedPaymentMethod && !selectedPaymentMethod.isActive ? "activate m-1 py-1 px-2" : "inactivate m-1 py-1 px-2"} disabled={updateLoading} onClick={updatePaymentMethodStatus}>{updateLoading ? "Saving" : selectedPaymentMethod && !selectedPaymentMethod.isActive ? "Activate" : "Inactivate"}</button>
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  )
}
