import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow,Dialog } from '@mui/material'
import { useSelector } from 'react-redux';
import PayoutRequestsHook from '../hooks/PayoutRequestsHook';
import PayoutPaymentMethodsHook from '../hooks/PayoutPaymentMethodsHook';
import { useAuthContext } from '../hooks/adminAuthHook'
export default function WithdrawRequests() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const { admin } = useAuthContext();
  const payoutrequests = useSelector((state) => state.payoutrequests.payoutrequests);
  const payoutPaymentMethods = useSelector((state) => state.payoutPaymentMethods.payoutPaymentMethods);
  const { getPayoutRequests } = PayoutRequestsHook();
  const { getPayoutPaymentMethods } = PayoutPaymentMethodsHook(); 
  useEffect(() => {
    getPayoutRequests()
    getPayoutPaymentMethods()
  }, [admin]);

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [search, setSearch] = useState('');
  const filteredRequests = payoutrequests && payoutrequests
    .filter(request => {
      const matchesSearch = (
        request.phone?.trim().toLowerCase().includes(search.trim().toLowerCase())
      );

      return matchesSearch
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // Deleting 
  const [requestToBeDelete, setRequestToBeDelete] = useState()
  const [open, setOpen] = React.useState(false);
  const handleClickDelete = (request) => {
    setOpen(true);
    setRequestToBeDelete(request)
  }

  const handleCancelDelete = () => {
    setOpen(false)
    setTimeout(() => {
      setRequestToBeDelete('')
    }, 1000);
  }

  // Deleting Withdrawl request
const [delLoading,setDelLoading]=useState(false)
  const deleteRequest=async()=>{
    setDelLoading(true)
    try {

      const response = await fetch(`${apiUrl}/auth/admins/referrals/delete/payout/request`, {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({requestId:requestToBeDelete._id})
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        setDelLoading(false)
        setRequestToBeDelete('')
        handleCancelDelete()
        getPayoutRequests()
      }
      if (!response.ok) {
        alert(json.message)
        setDelLoading(null)
      }
      
    } catch (error) {
      setDelLoading(false)
      
    }
  }

  const [openNew, setOpenNew] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState('')
    // approving the new request
const [phone, setPhone] = useState("");
const [amount, setAmount] = useState("");
const [payment_Method, setPayment_Method]=useState("");
const [slip, setSlip] = useState("");


const handleImage = (e) => {
  const file = e.target.files[0];
  TransformFile(file)
  if (file) {
      if (file.size > 5 * 1024 * 1024) {
          alert('File size exceeds the 5MB limit. Please select a smaller file.');
      } else {
          TransformFile(file);
      }
  }
};

const TransformFile = (file) => {
  const reader = new FileReader();
  if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSlip(reader.result);
      };
  } else {
    setSlip('');
  }
};

  const handleApprove = (data) => {
    setOpenNew(true)
    setSelectedRequest(data)
    setPhone(data?.phone)
    setAmount(data?.amount)

  }

  const handleCancel=()=>{
    setOpenNew(false)
    setSelectedRequest('')
    setPhone('')
  }


  const [updateLoading,setUpdateLoading]=useState(false)
  const approveWithdrawlRequest=async(e)=>{
    e.preventDefault()
    setUpdateLoading(true)
     try {
       const response = await fetch(`${apiUrl}/auth/admins/referrals/approve/payout/request`, {
         method: "POST",
         headers: {
           'Authorization': `Bearer ${admin.token}`,
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({requestId:selectedRequest._id,phone,amount,payment_Method,slip})
       })
 
       const json = await response.json()
       if (response.ok) {
         alert(json.message)
         getPayoutRequests()
         setOpenNew(false);
         setUpdateLoading(null)
         handleCancel()
       }
       if (!response.ok) {
         alert(json.message)
         setUpdateLoading(false)
       }
     } catch (error) {
      setUpdateLoading(false)
     }
  }
  
  return (
    <div className='row px-2'>
      <div className="col-md-12 data_table p-2 pb-5">
        <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
          <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
            <h6>All Requests</h6>
          </div>
          <div className="d-flex right my-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
          </div>
        </div>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
            <TableHead>
              <TableRow>
                <TableCell className='th text-center'>SN</TableCell>
                <TableCell className='th text-center'>Referrals</TableCell>
                <TableCell className='th text-center'>Total Balance</TableCell>
                <TableCell className='th text-center'>Paid Amount</TableCell>
                <TableCell className='th text-center'>Remaining Balance</TableCell>
                <TableCell className='th text-center'>Pending Withdraws</TableCell>
                <TableCell className='th text-center'>Payment Method</TableCell>
                <TableCell className='th text-center'>Account Holder</TableCell>
                <TableCell className='th text-center'>Acc #</TableCell>
                <TableCell className='th text-center'>Amount</TableCell>
                <TableCell className='th text-center'>Status</TableCell>
                <TableCell className='th text-center'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRequests && filteredRequests.length > 0 ? filteredRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((request, index) => (
                  <TableRow key={request._id}>
                    <TableCell className='text-center td'>{index + 1}</TableCell>
                    <TableCell className='text-center td'>{request.phone}</TableCell>
                    <TableCell className='text-center td'><span className='badge bg-warning text-white px-2 py-1'>{request.totalBalance.toLocaleString()} PKR</span></TableCell>
                    <TableCell className='text-center td'><span className='badge bg-success text-white px-2 py-1'>{request.totalPaidAmount.toLocaleString()} PKR</span></TableCell>
                    <TableCell className='text-center td'><span className='badge bg-info text-white px-2 py-1'>{request.remainingBalance.toLocaleString()} PKR</span></TableCell>
                    <TableCell className='text-center td'><span className='badge bg-danger text-white px-2 py-1'>{request.totalPendingWithdrawals.toLocaleString()} PKR</span></TableCell>
                    <TableCell className='text-center td'>{request.payment_Method}</TableCell>
                    <TableCell className='text-center td'>{request.account_Holder}</TableCell>
                    <TableCell className='text-center td'>{request.transfer_To_Acc_No}</TableCell>
                    <TableCell className='text-center td'>{request.amount.toLocaleString()} PKR</TableCell>
                    <TableCell className='text-center td'><span className={`${request.isApproved ? 'badge bg-success text-white p-1' : "badge bg-danger text-white px-1 py-2"}`}>{request.isApproved ? "Approved" : "Pending"}</span></TableCell>
                    <TableCell className='td text-center'>
                      <div class="btn-group" role="group" aria-label="Basic example">
                        {!request.isApproved &&
                        <button className="inactivate_shops py-1 px-2  m-1" onClick={() => handleClickDelete(request)}>Delete</button>
                        }
                        <button onClick={() => handleApprove(request)} className="activate_shops py-1 px-2 m-1" disabled={request.isApproved} >{request.isApproved ? "Approved" : "Approve"}</button>
                      </div>
                    </TableCell>
                  </TableRow>
                )) :
                <TableRow className='border-0'>
                  <TableCell colSpan={4}></TableCell>
                  <TableCell className='td text-center'>No result</TableCell>
                  <TableCell colSpan={4}></TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={filteredRequests && filteredRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          style={{
            color: '#5932EA',
            fontSize: '12px',
            fontWeight: '700',
            textTransform: 'capitalize',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      
      {/* Deleting */}
      <Dialog className='shops_modal p-0'
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="alert_icon text-center">
                <div className="inactivate_icon  py-2">  
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
              </svg>
                </div>
                <div className="content px-4">
                  <h6 className='mt-3'>Alert !</h6>
                  <p>Do you want to delete the withdrawl request ?</p>
                </div>
              </div>
              <button className="cancel m-1 py-1 px-2"  onClick={handleCancelDelete}disabled={delLoading}>Cancel</button>
              <button className="inactivate m-1 py-1 px-2"onClick={deleteRequest} disabled={delLoading}>{delLoading?"Deleting...":"Delete"}</button>
            </div>
          </div>
        </div>

      </Dialog>


      
 {/* approving withdrawls request moda; */}

 <Dialog className='p-0'
        open={openNew}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12  pb-2 px-0 m-0">
              <form onSubmit={approveWithdrawlRequest}>
                <div className="card add_new_package_card">
                  <div className="card-header">
                    Approving Wihdrawl Request
                    </div>
                  <div className="card-body">
                  
                    <div className="image-container text-center data my-1">
                        <div className="image-wrapper">
                            <img
                                src={slip ? slip : "https://via.placeholder.com/100"}
                                alt="team-member"
                                className="profile-img"
                                onClick={() => document.getElementById('fileInput').click()}
                            />
                            <span className="add-icon" onClick={() => document.getElementById('fileInput').click()}>
                                +
                            </span>
                        </div>
                        <input
                            type="file"
                            accept='image/*'
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleImage}
                        />
                    </div>
                    <div className="data my-1">
                      <label htmlFor="" >Referral Phone:</label>
                      <input type="text" value={phone} className='mt-1' id="" required  disabled/>
                    </div>
                  
                    <div className="data my-1">
                      <label htmlFor="">Amount:</label>
                      <input type="number" className='mt-1' id="" value={amount} disabled required/>
                    </div>
                    <div className="data my-1">
                      <label htmlFor="">Payment Method</label>
                      <select name="" id=""  value={payment_Method} onChange={(e) => setPayment_Method(e.target.value)} className='mt-1'required>
                        <option value="">Select</option>
                        {payoutPaymentMethods&&payoutPaymentMethods.length>0?payoutPaymentMethods.map((data,index)=>(
                        <option key={index} value={data.payment_Gateway}>{data.payment_Gateway}</option>

                        )):
                        <option value="Jazz Cash">No payment method avilable</option>
                        }
                        
                      </select>
                    </div>
                   
                    <div className="text-end mt-3">
                      <button className="btn cancel_btn mx-1" type='button' onClick={() => handleCancel()} disabled={updateLoading}>Cancel</button>
                      <button className="btn save_btn mx-1" type='submit' disabled={updateLoading}>{updateLoading ? "Approving" : "Approve"}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
