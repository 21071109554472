import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Box, Skeleton, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import ShopsReferralsHook from '../hooks/ShopsReferralsHook'

export default function ShopsReferrals() {
    const shopsReferrals = useSelector((state) => state.shopsReferrals.shopsReferrals);
    const { loading, getShopsReferrals } = ShopsReferralsHook()

    useEffect(() => {
        getShopsReferrals()
    }, [])
    const rowsPerPageOptions = [10, 30, 60];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [search, setSearch] = useState('');

    const filteredShopsReferrals = shopsReferrals && shopsReferrals
        .filter(shop => {
            const matchesSearch = (
                shop.name?.trim().toLowerCase().includes(search.trim().toLowerCase())
            )
            return matchesSearch
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // View Details
    const [openDetails, setOpenDetails] = useState(null);
    const toggleOpen = (shopId) => {
        setOpenDetails((prevId) => (prevId === shopId ? null : shopId));
    };

    return (
        <div className='row px-2'>
            {loading && (shopsReferrals && shopsReferrals.length < 1) ?
                <>

                    <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
                    <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
                    <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
                    <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
                </>
                :
                <div className="col-md-12 data_table p-2 pb-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                        <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                            <h6>Shops Referrals</h6>
                        </div>
                        <div className="d-flex right my-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                        </div>
                    </div>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='th text-center '>#</TableCell>
                                    <TableCell className='th text-center '>RefferedBy</TableCell>
                                    <TableCell className='th text-center '>Phone</TableCell>
                                    <TableCell className='th text-center '>Total Referrals</TableCell>
                                    <TableCell className='th text-center '>Sigup Bonus</TableCell>
                                    <TableCell className='th text-center '>Package Purchase Bonus</TableCell>
                                    <TableCell className='th text-center'>View</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredShopsReferrals && filteredShopsReferrals.length > 0 ? filteredShopsReferrals
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((referral, index) => (
                                        <React.Fragment>
                                            <TableRow key={index}>
                                                <TableCell className='td text-center'>{index + 1}</TableCell>
                                                <TableCell className='td text-center '>{referral.name}</TableCell>
                                                <TableCell className='td text-center '>{referral.phone}</TableCell>
                                                <TableCell className='td text-center '>{referral.referredShops && referral.referredShops.length}</TableCell>
                                                <TableCell className='td text-center '>{referral.referredShops && referral.referredShops.length}</TableCell>
                                                <TableCell className='td text-center '>{referral.referredShops && referral.referredShops.filter(r => r.isRewarded).length}</TableCell>
                                                <TableCell className='text-center td'>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => toggleOpen(referral._id)}
                                                    >
                                                        {openDetails === referral._id ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )}
                                                    </IconButton>


                                                </TableCell>
                                            </TableRow>
                                            {/* Nested Details */}
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={12}>
                                                    <Collapse in={openDetails === referral._id} timeout="auto" unmountOnExit>
                                                        <Box sx={{ margin: 1 }} >
                                                            <div className="d-flex justify-content-between searchbar pb-2 pt-3 px-1 my-auto">
                                                                <div className="d-flex left my-auto">
                                                                    <h5>Referred Shops</h5>
                                                                </div>
                                                            </div>
                                                            <Table aria-label="orders">
                                                                <TableHead className='order_header'>
                                                                    <TableRow>
                                                                        <TableCell className='th text-center '>#</TableCell>
                                                                        <TableCell className='th text-center '>Shops</TableCell>
                                                                        <TableCell className='th text-center '>Phone</TableCell>
                                                                        <TableCell className='th text-center '>Signup Bonus</TableCell>
                                                                        <TableCell className='th text-center '>Package Purchase Bonus</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {referral.referredShops.length > 0 ? referral.referredShops.map((order, index) => (
                                                                        <TableRow key={order._id}>
                                                                            <TableCell className='text-center td'>{index + 1}</TableCell>
                                                                            <TableCell className='text-center td'>{order.referredShopName}</TableCell>
                                                                            <TableCell className='text-center td'>{order.referredShopPhone}</TableCell>
                                                                            <TableCell className='text-center td'>{order.signupBonus} Days free usage</TableCell>
                                                                            <TableCell className='text-center td'>{order.isRewarded?order.packagePurchaseBonus+' Days free usage':"Pending"}</TableCell>
                                                                        </TableRow>
                                                                    )) :
                                                                        <TableRow>
                                                                        <TableCell colSpan={3}></TableCell>
                                                                        <TableCell className='td text-center'>No result</TableCell>
                                                                        <TableCell colSpan={3}></TableCell> 
                                                                        </TableRow>
                                                                    }


                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )) :
                                    <TableRow className='border-0'>
                                        <TableCell colSpan={3}></TableCell>
                                        <TableCell className='td text-center'>No result</TableCell>
                                        <TableCell colSpan={3}></TableCell>      
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component='div'
                        count={filteredShopsReferrals && filteredShopsReferrals.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        style={{
                            color: '#5932EA',
                            fontSize: '12px',
                            fontWeight: '700',
                            textTransform: 'capitalize',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            }
        </div>
    )
}
