import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer,TablePagination, TableHead, TableRow, Menu, MenuItem, Box, Dialog, TextField,Skeleton } from '@mui/material'
import OrdersHook from '../hooks/OrdersHook'
import { useSelector } from 'react-redux';

export default function Orders() {

  const {loading,getOrders } = OrdersHook()
  
  const orders = useSelector((state) => state.orders.orders)
  console.log('orders',orders)
  useEffect(() => {
    document.title = 'Orders';
    getOrders()
  }, [])

  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState('')
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  
  // Getting filtered Orders
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const isWithinFilter = (order) => {
    const orderCreatedAt = new Date(order.createdAt);
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    // Ensure date strings are converted to Date objects and are valid
    const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

    if (filterValue === 'today') {
      return orderCreatedAt.toDateString() === today.toDateString();
    } else if (filterValue === 'thisWeek') {
      return orderCreatedAt >= sevenDaysAgo && orderCreatedAt <= today;
    } else if (filterValue === 'custom') {
      // Handle empty strings gracefully
      const fromDate = dateFrom ? new Date(dateFrom) : undefined;
      const toDate = dateTo ? new Date(dateTo) : undefined;
      if (!isValidDate(fromDate) || !isValidDate(toDate)) {

        return false;
      }

      // Check if shopCreatedAt is within the custom date range
      return orderCreatedAt >= fromDate && orderCreatedAt <= toDate;
    } else {
      return true; // 'all' case or any other default case
    }
  };
  const filteredOrders = orders && orders
    .filter(order => {
      const matchesSearch = (
        order.customer_Name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        order.name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        order.delivery_Date?.trim()===search.trim() ||
        order.order_Status?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        order.createdAt?.trim()===search.trim() ||
        order.new_Order_Id.trim()===search.trim()||
        order.suite_Type?.trim().toLowerCase().includes(search.trim().toLowerCase())
      );
      return matchesSearch && isWithinFilter(order);
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // filter menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuStyles = {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
  };

  const menuItemStyles = {
    padding: '4px 12px',
    fontSize: '12px',
    color: '#000',
    cursor: 'pointer'
  };

  const menuItemHoverStyles = {
    backgroundColor: '#f5f5f5'
  };



  // custom date range filter
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = (shop) => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  }

  
  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const collapsed = useSelector((state) => state.collapsed.collapsed);


  return (
    <div className={`${collapsed ? "collapsed dashboard mt-5" : "main dashboard mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-2">
          {loading &&(orders&&orders.length<1)?
          <>
          <div className="col-md-6 col-lg-3 p-2">
                                <Box className='p-0 m-0'>
                                    <Skeleton />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation={false} />
                                </Box>
                            </div>
                            <div className="col-md-6 col-lg-3 p-2">
                                <Box className='p-0 m-0'>
                                    <Skeleton />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation={false} />
                                </Box>
                            </div>
                            <div className="col-md-6 col-lg-3 p-2">
                                <Box className='p-0 m-0'>
                                    <Skeleton />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation={false} />
                                </Box>
                            </div>
                            <div className="col-md-6 col-lg-3 p-2">
                                <Box className='p-0 m-0'>
                                    <Skeleton />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation={false} />
                                </Box>
                            </div>
          </>:
          <>
           <div className="col-md-6 col-lg-3 p-2">
            <div className="card orders_received p-0 m-0">

              <div className="card-body px-0 pb-1">
                <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                  <div className=" left">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                    </svg>
                    <h6 className='ms-2'>Received Orders</h6>
                  </div>
                  <div className="right">
                    <p>
                      {orders && orders.filter(order => order.order_Status.trim().toLowerCase() === 'received').length}
                    </p>
                  </div>
                </div>
                <hr className='px-0 pb-0 mb-2' />
                <div className="d-flex  px-3 pb-0 pt-0">
                <p>Total number of received orders</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6 col-lg-3 p-2">
            <div className="card orders_processing">
              <div className="card-body px-0 pb-1">
                <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                  <div className=" left">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 fa-spin">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                    <h6 className='ms-2'>Processing Orders</h6>
                  </div>
                  <div className="right">
                    <p>
                      {orders && orders.filter(order => order.order_Status.trim().toLowerCase() === 'processing').length}
                    </p>
                  </div>
                </div>
                <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Number of orders under processing</p>
                    </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-2">
            <div className="card orders_completed">

              <div className="card-body px-0 pb-1">
                <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                  <div className=" left">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                    </svg>

                    <h6 className='ms-2'>Completed Orders</h6>
                  </div>
                  <div className="right">
                    <p>
                      {orders && orders.filter(order => order.order_Status.trim().toLowerCase() === 'completed').length}
                    </p>
                  </div>
                </div>
                <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Number of completed orders</p>
                    </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-2">
            <div className="card orders_delivered">

              <div className="card-body px-0 pb-1">
                <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                  <div className=" left">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                    <h6 className='ms-2'>Deliverd Orders</h6>
                  </div>
                  <div className="right">
                    <p>
                      {orders && orders.filter(order => order.order_Status.trim().toLowerCase() === 'delivered').length}
                    </p>
                  </div>
                </div>
                <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Number of ordes delivered successfully</p>
                    </div>
              </div>
            </div>
          </div>
          </>
        }
         
        </div>

        {/* orders details */}
        <div className="row px-3 mt-md-4">
          {loading &&(orders&&orders.length<1)?
          <>
           <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
            <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
            <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
            <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
          </>:
          <>
           <div className="col-md-12 data_table p-2 pb-5">
            <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
              <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                <h6>{filterValue === 'thisWeek' ? "This week new" : filterValue === 'today' ? "Today new" : filterValue === 'custom' ? "Custome date" : "All"} Orders <span>{dateFrom && dateTo && `( ${dateFrom} to ${dateTo} )`}</span></h6>
              </div>
              <div className="d-flex right my-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />

                {/* filters */}
                <button
                  className='filter_btn'
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path strokeLinecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                  </svg>

                </button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    style: menuStyles,
                  }}
                >
                  <MenuItem
                    onClick={() => { handleClose(); setFilterValue(''); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => { handleClose(); setFilterValue('today'); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Today
                  </MenuItem>
                  <MenuItem
                    onClick={() => { handleClose(); setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    This Week
                  </MenuItem>

                  <MenuItem
                    onClick={() => { handleClickOpenModal(); setFilterValue('custom') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Custom Date
                  </MenuItem>
                  <MenuItem
                    onClick={() => { setFilterValue(''); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Default
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                <TableHead>
                  <TableRow>
                    <TableCell className='th text-center'>SN</TableCell>
                    <TableCell className='th text-center'>Order Date</TableCell>
                    <TableCell className='th text-center'>Order #</TableCell>
                    <TableCell className='th text-center'>Customers</TableCell>
                    <TableCell className='th text-center'>Shop</TableCell>
                    <TableCell className='th text-center'>Suits</TableCell>
                    <TableCell className='th text-center'>Amount</TableCell>
                    <TableCell className='th text-center'>Total</TableCell>
                    <TableCell className='th text-center'>Paid</TableCell>
                    <TableCell className='th text-center'>Delivery Date</TableCell>
                    <TableCell className='th text-center'>Updated on</TableCell>
                    <TableCell className='th text-center'>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOrders && filteredOrders.length > 0 ? filteredOrders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order, index) => (
                    <TableRow key={order._id}>
                      <TableCell className='text-center td'>{index + 1}</TableCell>
                      <TableCell className='text-center td'>
                          {new Date(order.createdAt).toLocaleDateString('en-GB').split('-').join('')}
                        </TableCell>
                        <TableCell className='text-center td'>{order.new_Order_Id}</TableCell>
                      <TableCell className='text-center td'>{order.customer_Name}</TableCell>
                      <TableCell className='text-center td'>{order.name}</TableCell>
                      <TableCell className='text-center td'>{order.total_Suites}</TableCell>
                      <TableCell className='text-center td'>{order.amount_Per_Suite.toLocaleString()}</TableCell>
                      <TableCell className='text-center td'>{(order.amount_Per_Suite * order.total_Suites).toLocaleString()}</TableCell>
                      <TableCell className='text-center td'>{order.paid_Amount.toLocaleString()}</TableCell>
                      <TableCell className='text-center td'>{new Date(order.delivery_Date).toLocaleDateString()}</TableCell>
                      <TableCell className='text-center td'>{order.update_On_Date?new Date(order.update_On_Date).toLocaleDateString():''}</TableCell>
                      <TableCell className='text-center td'>{order.order_Status.toLowerCase() === 'received' ? <span className="received_Status px-2 py-1">Received</span> : order.order_Status.toLowerCase() === 'processing' ? <span className="process_Status px-2 py-1">Processing</span> : order.order_Status.toLowerCase() === 'completed' ? <span className="completed_Status  px-2 py-1">Completed</span> : <span className="delivered_Status  px-2 py-1">Delivered</span>}</TableCell>
                    </TableRow>
                  )) :
                    <TableRow className='border-0'>
                      <TableCell></TableCell>
                      <TableCell colSpan={5}></TableCell>
                      <TableCell className='td text-center'>No result</TableCell>
                      <TableCell></TableCell>
                      <TableCell colSpan={5}></TableCell>
                    
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component='div'
                  count={filteredOrders&&filteredOrders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  style={{
                    color: '#5932EA',
                    fontSize: '12px',
                    fontWeight: '700',
                    textTransform: 'capitalize',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
          </div>
          </>
          }
         
        </div>
      </div>


      {/* Custom Date Range Modal */}
      <Dialog className='custom_date_modal p-0'
        open={openModal}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="content px-4 text-center">
                <h6 className='my-3 '>Custom filter!</h6>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 0.5, width: '15ch' },  // Reduced margin
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="date-from"
                      label="Date From"
                      type="date"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                      InputLabelProps={{
                        shrink: true,  // Ensures label doesn't overlap with date input
                      }}
                      sx={{
                        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                      }}
                    />
                    <TextField
                      id="date-to"
                      label="Date To"
                      type="date"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                      InputLabelProps={{
                        shrink: true,  // Ensures label doesn't overlap with date input
                      }}
                      sx={{
                        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                      }}
                    />
                  </div>
                </Box>
              </div>
              <button className="cancel m-1 py-1 px-2" onClick={() => { handleCloseModal(); setDateFrom(''); setDateTo(''); setFilterValue('') }}>Cancel</button>
              <button className="cancel m-1 py-1 px-2 active" onClick={handleCloseModal}>Filter</button>
            </div>
          </div>
        </div>

      </Dialog>

    </div>
  )
}
