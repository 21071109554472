import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow,Dialog } from '@mui/material'
import PackagesHook from '../hooks/PackagesHook';
import TrialsHook from '../hooks/TrialsHook';
import { updateTrial } from '../redux/reducers/trialSlice'
import { useAuthContext } from '../hooks/adminAuthHook';
import PaymentMethods from './PaymentMethods'
export default function PlanAndPackages() {
  const { admin } = useAuthContext();
  const apiUrl = process.env.REACT_APP_API_URL;
  const packages = useSelector((state) => state.packages.packages);
  const trials = useSelector((state) => state.trials.trials);
  const collapsed = useSelector((state) => state.collapsed.collapsed);
  const dispatch = useDispatch();
  const { getPackages } = PackagesHook();
  const { getTrials } = TrialsHook(); // Assuming updateTrial is a function in TrialsHook

  const [editingTrial, setEditingTrial] = useState(null);
  const [editedValues, setEditedValues] = useState({});

  useEffect(() => {
    document.title = 'Plans & Methods';
    getTrials();
    getPackages();
  }, [admin]);

  const handleEdit = (trial) => {
    setEditingTrial(trial._id);
    setEditedValues({ days: trial.days, isActive: trial.isActive, _id: trial._id });
  };
  const handleCancel = () => {
    setEditingTrial(null);
    setEditedValues({});
  };
  const [loading, setLoading] = useState(null)
  
  // Updating Trial
  const handleUpdate = async () => {
    setLoading(true)

    try {
      const response = await fetch(`${apiUrl}/auth/admins/trials/update`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ trial: editedValues })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        setLoading(null)
        dispatch(updateTrial(json.data))
        setEditingTrial(null);
        setEditedValues({});
      }
      if (!response.ok) {
        alert(json.message)
        setLoading(null)
      }
    } catch (error) {
      setLoading(null)
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [name]: name === 'days' ? parseInt(value) : value === 'Enabled',
    }));
  };

  // Packages


  // Package Edition
  const [editedPackage,setEditedPackage]=useState(null)
  // Adding a new Package
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [price_After_Discount, setPrice_After_Discount] = useState("");
  const [duration, setDuration] = useState("");
  const [isRecommended, setIsRecommended] = useState();
  const [isActive, setIsActive] = useState();
  const [additional, setAdditional] = useState("");
  const [features, setFeatures] = useState([""]);

  const [openNew, setOpenNew] = React.useState(false);
  const handleClickOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
    setName('')
    setPrice('')
    setPrice_After_Discount('')
    setDuration('')
    setIsActive('')
    setIsRecommended('')
    setAdditional('')
    setEditedPackage('')
    setFeatures([""])
  }

const handleEditView=(data)=>{
  setEditedPackage(data);
  setOpenNew(true);
  setName(data.name || "");
  setPrice(data.price || "");
  setPrice_After_Discount(data.price_After_Discount || "");
  setDuration(data.duration|| "");
  setIsActive(data.isActive);
  setIsRecommended(data.isRecommended);
  setAdditional(data.additional || "");
  setFeatures(data.features || [""]);
}
  // Handle Select Input for Boolean Values
  const handleSelectChange = (setter) => (e) => {
    const value = e.target.value === "true" ? true : false;
    setter(value);
  };
  // Handle Features Change
  const handleFeatureChange = (index) => (e) => {
    const newFeatures = [...features];
    newFeatures[index] = e.target.value;
    setFeatures(newFeatures);
  };

  const addFeatureInput = () => {
    setFeatures([...features, '']); // Add a new empty input field
  };

  const removeFeatureInput = (index) => {
    setFeatures(features.filter((_, i) => i !== index)); // Remove the input field at the specified index
  };

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // API Call to add a new package
  const [loading1, setLoading1] = useState(false)
  const addNewPackage = async (e) => {
    e.preventDefault()
    setLoading1(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/packages/add`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, price, price_After_Discount, duration, isActive, isRecommended, additional, features: features.filter((feature) => feature.trim() !== '') })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        getPackages()
        setOpen(false);
        setLoading1(null)
        handleCloseNew()
      }
      if (!response.ok) {
        alert(json.message)
        setLoading1(null)
      }
    } catch (error) {
      setLoading1(null)
    }
  }

  const updatePackage = async (e) => {
    e.preventDefault()
    setLoading1(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/packages/update`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({package:{_id:editedPackage._id, name, price, price_After_Discount, duration, isActive, isRecommended, additional, features: features.filter((feature) => feature.trim() !== '')}})
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        getPackages()
        setOpen(false);
        setLoading1(null)
        handleCloseNew()

      }
      if (!response.ok) {
        alert(json.message)
        setLoading1(null)
      }
    } catch (error) {
      setLoading1(null)
    }
  }

//uploading a package



  const [search, setSearch] = useState('');
  const filteredPackages = packages && packages
    .filter(newPackage => {
      const matchesSearch = (
        newPackage.name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        newPackage.price?.includes(search.trim()) ||
        newPackage.duration?.trim().toLowerCase().includes(search.trim().toLowerCase())
      );

      return matchesSearch
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));



  // Activate/Inactivate state
  const [selectedPackage, setSelectedPackage] = useState()
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (newPackage) => {
    setOpen(true);
    setSelectedPackage(newPackage)

  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSelectedPackage('')
    }, 1000);
  }
  // Activating/Inactivating shops
  const [updateLoading, setUpdateLoading] = useState(false)
  const updatePackageStatus = async () => {
    setUpdateLoading(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/packages/update/status`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ packageId: selectedPackage && selectedPackage._id })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        getPackages()
        setOpen(false);
        setUpdateLoading(null)

      }
      if (!response.ok) {
        alert(json.message)
        setUpdateLoading(null)
      }
    } catch (error) {
      setLoading1(null)
    }
  }

  return (
    <div className={`${collapsed ? "collapsed packages mt-5" : "main packages mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-2">
          <div className="col-md-12 mt-4">
            {/* Nav Pills */}
            <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
              <li className="nav-item m-1" role="presentation">
                <button className="active" id="pills-trial-tab" data-bs-toggle="pill" data-bs-target="#pills-trial" type="button" role="tab" aria-controls="pills-trial" aria-selected="true">
                  Trials
                </button>
              </li>
              <li className="nav-item m-1" role="presentation">
                <button className="" id="pills-packages-tab" data-bs-toggle="pill" data-bs-target="#pills-packages" type="button" role="tab" aria-controls="pills-packages" aria-selected="false">
                  Packages
                </button>
              </li>
              <li className="nav-item m-1" role="presentation">
                <button className="" id="pills-payment_methods-tab" data-bs-toggle="pill" data-bs-target="#pills-payment_methods" type="button" role="tab" aria-controls="pills-payment_methods" aria-selected="false">
                  Payment Methods
                </button>
              </li>
            </ul>

            {/* Tab Content */}
            <div className="tab-content" id="pills-tabContent">
              {/* Trials Tab Pane */}
              <div className="tab-pane fade show active" id="pills-trial" role="tabpanel" aria-labelledby="pills-trial-tab">
                <div className="row">
                  {trials && trials.map((data) => (
                    <div className="col-lg-6 col-m-6 col-xl-4 col-sm-12" key={data._id}>
                      <div className="card trial_card">
                        <div className="card-header">
                          Free Trial
                        </div>
                        <div className="card-body">
                          <div className="data">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                            </svg>
                            <input type="number" min='0' value={editingTrial === data._id ? editedValues.days : data.days} className='my-1' onChange={handleInputChange} name="days" id="" disabled={editingTrial !== data._id} required />
                          </div>
                          <div className="data">
                            <i className={`fa-solid fa-circle-dot ${data.isActive ? 'active' : 'inactive'}`}></i>
                            <select value={editingTrial === data._id ? (editedValues.isActive ? 'Enabled' : 'Disabled') : (data.isActive ? 'Enabled' : 'Disabled')} className='my-1' onChange={handleInputChange} name="isActive" id="" disabled={editingTrial !== data._id} required>
                              <option value="Enabled">Enabled</option>
                              <option value="Disabled">Disabled</option>
                            </select>
                          </div>
                          {editingTrial === data._id ? (
                            <>
                              <button className="btn cancel_btn my-1" onClick={handleCancel} disabled={loading}>Cancel</button>
                              <button className="btn save_btn my-1" onClick={() => handleUpdate()} disabled={loading}>{loading ? "Saving" : "Save"}</button>
                            </>
                          ) : (
                            <button className="btn edit_btn my-1" onClick={() => handleEdit(data)} disabled={loading}>Edit</button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Packages Tab Pane */}
              <div className="tab-pane fade" id="pills-packages" role="tabpanel" aria-labelledby="pills-packages-tab">
                <div className="row px-2">
                  <div className="col-md-12 data_table p-2 pb-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                      <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                        <h6>All Packages</h6>
                      </div>
                      <div className="d-flex right my-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                        <button className='add_btn btn ms-1' onClick={() => handleClickOpenNew()}>Add</button>
                      </div>
                    </div>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                        <TableHead>
                          <TableRow>
                            <TableCell className='th text-center'>SN</TableCell>
                            <TableCell className='th text-center'>Package Name</TableCell>
                            <TableCell className='th text-center'>Duration</TableCell>
                            <TableCell className='th text-center'>Actual Price</TableCell>
                            <TableCell className='th text-center'>Discounted Price</TableCell>
                            <TableCell className='th text-center'>Recommended</TableCell>
                            <TableCell className='th text-center'>Status</TableCell>
                            <TableCell className='th text-center'>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredPackages && filteredPackages.length > 0 ? filteredPackages
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((newPackage, index) => (
                              <TableRow key={newPackage._id}>
                                <TableCell className='text-center td'>{index + 1}</TableCell>
                                <TableCell className='text-center td'>{newPackage.name}</TableCell>
                                <TableCell className='text-center td'>{newPackage.duration} days</TableCell>
                                <TableCell className='text-center td'>{newPackage.price.toLocaleString()} PKR</TableCell>
                                <TableCell className='text-center td'>{newPackage.price_After_Discount.toLocaleString()} PKR</TableCell>
                                <TableCell className='text-center td'>{newPackage.isRecommended ? "Yes" : "No"}</TableCell>
                                <TableCell className='td text-center'>{newPackage.isActive ? <span className="active_span px-2 py-1">Active</span> : <span className="inactive_span px-2 py-1">Inactive</span>}</TableCell>
                                <TableCell className='td text-center'>
                                  <div class="btn-group" role="group" aria-label="Basic example">

                                    <button className="edit_btn py-1 px-2  m-1" onClick={()=>handleEditView(newPackage)}>Edit</button>
                                    <button onClick={() => handleClickOpen(newPackage)} className={newPackage.isActive ? "inactivate_shops py-1 px-2 m-1" : "activate_shops py-1 px-2 m-1"}>{newPackage.isActive ? "Inactivate" : "Activate"}</button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )) :
                            <TableRow className='border-0'>
                              <TableCell colSpan={3}></TableCell>
                              <TableCell className='td text-center'>No result</TableCell>
                              <TableCell colSpan={4}></TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions}
                      component='div'
                      count={filteredPackages && filteredPackages.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      style={{
                        color: '#5932EA',
                        fontSize: '12px',
                        fontWeight: '700',
                        textTransform: 'capitalize',
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>


              {/* Payment Methods */}
              <div className="tab-pane fade" id="pills-payment_methods" role="tabpanel" aria-labelledby="pills-payment_methods-tab">
                <PaymentMethods/>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Activate/Inactivate Modal */}
      <Dialog className='shops_modal p-0'
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="alert_icon text-center">
                <div className={selectedPackage && !selectedPackage.isActive ? "activate_icon  py-2" : "inactivate_icon  py-2"}>
                  {selectedPackage && !selectedPackage.isActive ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 ">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  }
                </div>
                <div className="content px-4">
                  <h6 className='mt-3'>Alert !</h6>
                  <p>Do you want to {selectedPackage && !selectedPackage.isActive ? "Activate" : "Inactivate"} {selectedPackage && selectedPackage.name} package?</p>
                </div>
              </div>
              <button className="cancel m-1 py-1 px-2" disabled={updateLoading} onClick={handleClose}>Cancel</button>
              <button className={selectedPackage && !selectedPackage.isActive ? "activate m-1 py-1 px-2" : "inactivate m-1 py-1 px-2"} disabled={updateLoading} onClick={updatePackageStatus}>{updateLoading ? "Saving" : selectedPackage && !selectedPackage.isActive ? "Activate" : "Inactivate"}</button>
            </div>
          </div>
        </div>

      </Dialog>

      {/* adding a new Package modal */}

      <Dialog className='p-0'
        open={openNew}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12  pb-2 px-0 m-0">
              <form onSubmit={editedPackage?updatePackage:addNewPackage}>
                <div className="card add_new_package_card">
                  <div className="card-header">
                  
                    {editedPackage ? "Edit Package" : "Add a new Package"}
                    </div>
                 
                  <div className="card-body">
                    <div className="data my-1">
                      <label htmlFor="" >Package Name:</label>
                      <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='mt-1' id="" required />
                    </div>
                  
                    <div className="data my-1">
                      <label htmlFor="">Discounted Price (PKR):</label>
                      <input type="number" min='0' className='mt-1' id=""placeholder='optional' value={price_After_Discount} onChange={(e) => setPrice_After_Discount(e.target.value)} />
                    </div>
                    <div className="data my-1">
                      <label htmlFor="">Actual Price (PKR):</label>
                      <input type="number" min='0' value={price} onChange={(e) => setPrice(e.target.value)} className='mt-1' id="" required />
                    </div>
                    <div className="data my-1">
                      <label htmlFor="">Package Duration (in days):</label>
                      <input type="number" min='0' className='mt-1' id="" required value={duration} onChange={(e) => setDuration(e.target.value)} />
                    </div>
                    <div className="data my-1">
                    <label htmlFor="is-recommended">Make it Recommended:</label>
                    <select
                      id="is-recommended"
                      required
                      value={isRecommended}
                      onChange={handleSelectChange(setIsRecommended)}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                  <div className="data my-1">
                    <label htmlFor="is-active">Activation:</label>
                    <select
                      id="is-active"
                      required
                      value={isActive}
                      onChange={handleSelectChange(setIsActive)}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                    <div className="data my-1">
                      <label htmlFor="">Additional Info:</label>
                      <input type="text" value={additional} placeholder='optional' onChange={(e) => setAdditional(e.target.value)} />
                    </div>
                    {/* Dynamic Benefits/Features Input */}
                    <div className="data my-1">
                      <label>Benefits (add multiple):</label>

                      {/* Dynamically Render Feature Inputs */}
                      {features.map((feature, index) => (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <input
                            type="text"
                            value={feature}
                            onChange={handleFeatureChange(index)}
                            className="form-control me-2"
                            placeholder={`Benefit ${index + 1}`}
                            required={features.filter((feature) => feature.trim() !== "").length === 0 && feature.trim() === ""}
                          />
                          {/* Conditionally Render Add or Remove Button */}
                          {index === features.length - 1 ? (
                            // Add button for the last input
                            <button
                              type="button"
                              className="bg-none border px-2 add_btn"
                              onClick={addFeatureInput}
                              disabled={!feature.trim()}
                            >
                              +
                            </button>
                          ) : (
                            // Remove button for all other inputs
                            <button
                              type="button"
                              className="bg-none border px-2 remove_btn"
                              onClick={() => removeFeatureInput(index)}
                            >
                              -
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="text-end mt-3">
                      <button className="btn cancel_btn mx-1" type='button' onClick={() => handleCloseNew()} disabled={loading1}>Cancel</button>
                      <button className="btn save_btn mx-1" type='submit' disabled={loading1}>{loading1 ? "Saving" : "Save"}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
