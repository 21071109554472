import { useState } from "react";
import { useAuthContext } from "./adminAuthHook";
import { getAllRewards } from "../redux/reducers/rewardsSlice";
import { useDispatch } from "react-redux";
export default function RewardsHook() {
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { admin } = useAuthContext();
  const [loading, setLoading] = useState(null);
  const getRewards = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/auth/admins/rewards_bonus/get`, {
        headers: {
          Authorization: `Bearer ${admin.token}`,
        },
      });

      const json = await response.json();
      if (response.ok) {
        setLoading(null);
        dispatch(getAllRewards(json.data));
      }
      if (!response.ok) {
        setLoading(null);
      }
    } catch (error) {
      console.log(error);
      setLoading(null);
    }
  };

  return { getRewards, loading };
}
