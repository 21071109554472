import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shops: []
};

export const shopsSlice = createSlice({
    name: 'shopsSlice',
    initialState,
    reducers: {
        // 1- Shops Reducers
        // a- getting get Shops
        getAllShops: (state, action) => {
            state.shops = action.payload;
        },
        // b- updating a Shop
        updateShop: (state, action) => {
            const data = action.payload; // Assuming action.payload is the shop object
            const index = state.shops.findIndex(shop => shop._id === data._id);
            if (index !== -1) {
                state.shops[index] = { ...state.shops[index], ...data };
            }
        }
    },
});

// Action creators are generated for each case reducer function
export const { getAllShops, updateShop } = shopsSlice.actions;

export default shopsSlice.reducer;
