import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Dialog, Box, Skeleton, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import RevenueHook from '../hooks/RevenueHook'
import { useAuthContext } from '../hooks/adminAuthHook'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function Revenue() {
  const { admin } = useAuthContext()
  const { loading, getRevenue } = RevenueHook()
  useEffect(() => {
    document.title = 'Revenue';
    getRevenue()
  }, [admin])

  const revenues = useSelector((state) => state.revenues.revenues)
 // Calculate total revenue
 const totalRevenue = revenues.reduce((total, revenue) => total + revenue.price, 0);
  // Helper functions to calculate date ranges
  const startOfWeek = new Date();
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Start of the current week (Sunday)
  startOfWeek.setHours(0, 0, 0, 0);

  const startOfMonth = new Date();
  startOfMonth.setDate(1); // Start of the current month
  startOfMonth.setHours(0, 0, 0, 0);

  const startOfYear = new Date();
  startOfYear.setMonth(0, 1); // Start of the current year (January 1)
  startOfYear.setHours(0, 0, 0, 0);

  // Calculate total revenue for the current week
  const weeklyRevenue = revenues
    .filter((revenue) => new Date(revenue.updated_On_Date) >= startOfWeek)
    .reduce((total, revenue) => total + revenue.price, 0);

  // Calculate total revenue for the current month
  const monthlyRevenue = revenues
    .filter((revenue) => new Date(revenue.updated_On_Date) >= startOfMonth)
    .reduce((total, revenue) => total + revenue.price, 0);

  // Calculate total revenue for the current year
  const yearlyRevenue = revenues
    .filter((revenue) => new Date(revenue.updated_On_Date) >= startOfYear)
    .reduce((total, revenue) => total + revenue.price, 0);

// Function to calculate the total revenue for each package
// Function to calculate the total revenue for each package
const packageRevenues = revenues.reduce((acc, item) => {
  const { package_Name, price } = item; // Assuming each revenue object has `package_Name` and `price`
  
  // Handle cases where package_Name is missing or empty
  const packageName = package_Name || 'Offers'; 

  if (!acc[packageName]) {
    acc[packageName] = 0;
  }
  
  acc[packageName] += price;
  
  return acc;
}, {});

// Sort the packageRevenues entries, ensuring 'Offers' appears first
const sortedRevenues = Object.entries(packageRevenues).sort(([nameA], [nameB]) => {
  if (nameA === 'Offers') return -1;
  if (nameB === 'Offers') return 1;
  return 0;
});


  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState('')

  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const today = new Date();
  const options = { month: 'short', day: 'numeric', year: 'numeric' };

  const formattedDate = today.toLocaleDateString('en-US', options);

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const isWithinFilter = (revenue) => {
    const revenueCreatedAt = new Date(revenue.updated_On_Date);
    // Ensure date strings are converted to Date objects and are valid
    const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());
    if (filterValue === 'today') {
      return revenueCreatedAt.toDateString() === today.toDateString();
    } else if (filterValue === 'thisWeek') {
      return revenueCreatedAt >= sevenDaysAgo && revenueCreatedAt <= today;
    } else if (filterValue === 'custom') {
      // Handle empty strings gracefully
      const fromDate = dateFrom ? new Date(dateFrom) : null;
      const toDate = dateTo ? new Date(dateTo) : null;

      // Ensure both dates are valid
      if ((!fromDate || !isValidDate(fromDate)) || (!toDate || !isValidDate(toDate))) {
        return false; // Invalid or missing date
      }

      // Check if revenueCreatedAt is within the custom date range
      return revenueCreatedAt >= fromDate && revenueCreatedAt <= toDate;
    } else {
      return true; // 'all' case or any other default case
    }
  };

  const filteredRevenue = revenues && revenues
    .filter(revenue => {
      const matchesSearch = (
        revenue.name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        revenue.email?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        revenue.package_Name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        revenue.phone?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        revenue.type?.trim().toLowerCase().includes(search.trim().toLowerCase())
      );
      // Only include revenues that match the search criteria, status filter, and are within the filter range
      return matchesSearch && isWithinFilter(revenue);
    })
    .sort((a, b) => new Date(b.updated_On_Date) - new Date(a.updated_On_Date));
  // filter menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuStyles = {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
  };

  const menuItemStyles = {
    padding: '4px 12px',
    fontSize: '12px',
    color: '#000',
    cursor: 'pointer'
  };

  const menuItemHoverStyles = {
    backgroundColor: '#f5f5f5'
  };

  // custom date range filter
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = (revenues) => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  }

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const collapsed = useSelector((state) => state.collapsed.collapsed);

  return (
    <div className={`${collapsed ? "collapsed dashboard mt-5" : "main dashboard mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
      <div className="row px-2">
          {loading && (revenues && revenues.length < 1) ?
            <>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  <Skeleton animation="wave" />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  <Skeleton animation="wave" />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  <Skeleton animation="wave" />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  <Skeleton animation="wave" />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
       
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
               
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                </Box>
              </div>
            </> :
            <>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_received p-0 m-0">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>
                        <h6 className='ms-2'>Total Revenue</h6>
                      </div>
                      <div className="right">
                        <p>
                          {totalRevenue.toLocaleString()} PKR
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                      <p>Updated at {formattedDate}</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_processing">
                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z" />
                      </svg>

                        <h6 className='ms-2'>This Week</h6>
                      </div>
                      <div className="right">
                      <p>
                      {weeklyRevenue.toLocaleString()} PKR
                    </p>

                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Updated at {formattedDate}</p>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_completed">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                    </svg>
                        <h6 className='ms-2'>This Month</h6>
                      </div>
                      <div className="right">
                      <p>
                      {monthlyRevenue.toLocaleString()} PKR
                    </p>

                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Updated at {formattedDate}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_delivered">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                      </svg>
                        <h6 className='ms-2'>This Year</h6>
                      </div>
                      <div className="right">
                        <p>
                        {yearlyRevenue.toLocaleString()} PKR
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Updated at {formattedDate}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

        </div>
        <div className="row ">
        <div className="revenue-container pb-0">
  {sortedRevenues.map(([name, price]) => (
    <div className="package-card m-0 px-0" key={name}>
      <div className="card package-revenue">
        <div className="card-body">
          <div className="d-flex justify-content-between top_content px-2 mb-2 mt-2">
            <div className="left">
              <h6 className='ms-2'>{name}</h6>
            </div>
            <div className="right">
              <p>{price.toLocaleString()} PKR</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>
</div>

        <div className="row px-3 mt-md-3 mt-4">
          {loading && (revenues && revenues.length < 1) ?
            <>
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
            </>
            :
            <div className="col-md-12 data_table p-2 pb-5">
              <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                  <h6>{filterValue === 'thisWeek' ? "This week" : filterValue === 'today' ? "Today" : filterValue === 'custom' ? "Custom date" : "Total"} Revenue <span>{dateFrom && dateTo && `( ${dateFrom} to ${dateTo} )`}</span></h6>
                </div>
                <div className="d-flex right my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                  <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                  <button
                    className='filter_btn'
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleMenuClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>

                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                      style: menuStyles,
                    }}
                  >
                    <MenuItem
                      onClick={() => { handleMenuClose(); setFilterValue(''); setDateFrom(''); setDateTo('') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      onClick={() => { handleMenuClose(); setFilterValue('today'); setDateFrom(''); setDateTo('') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      Today
                    </MenuItem>
                    <MenuItem
                      onClick={() => { handleMenuClose(); setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      This Week
                    </MenuItem>
                    <MenuItem
                      onClick={() => { handleClickOpenModal(); setFilterValue('custom') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      Custom Date
                    </MenuItem>
                    <MenuItem
                      onClick={() => { setFilterValue(''); setDateFrom(''); setDateTo('') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      Default
                    </MenuItem>
                  </Menu>

                </div>
              </div>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className='th text-center'>SN</TableCell>
                      <TableCell className='th text-center'>Date</TableCell>
                      <TableCell className='th text-center'>Shop Name</TableCell>
                      <TableCell className='th text-center'>Email</TableCell>
                      <TableCell className='th text-center'>Phone</TableCell>
                      <TableCell className='th text-center'>Address</TableCell>
                      <TableCell className='th text-center'>Revenue Type</TableCell>
                      <TableCell className='th text-center'>Days</TableCell>
                      <TableCell className='th text-center'>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRevenue && filteredRevenue.length > 0 ? filteredRevenue
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((revenues, index) => (
                        <React.Fragment>
                          <TableRow key={index}>
                            <TableCell className='td text-center'>{index + 1}</TableCell>
                            <TableCell className='text-center td'>
                              {new Date(revenues.updated_On_Date).toLocaleDateString('en-GB').split('-').join('')}
                            </TableCell>
                            <TableCell className='td text-center'>{revenues.name}</TableCell>
                            <TableCell className='td text-center'>{revenues.email}</TableCell>
                            <TableCell className='td text-center'>{revenues.phone}</TableCell>
                            <TableCell className='td text-center'>{revenues.address}</TableCell>
                            <TableCell className='td text-center'>{revenues.package_Name} {revenues.type}</TableCell>
                            <TableCell className='td text-center'>{revenues?.days}</TableCell>
                            <TableCell className='td text-center'>{revenues?.price}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      )) :
                      <TableRow className='border-0'>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell className='td text-center'>No result</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                count={filteredRevenue && filteredRevenue.length}
                rowsPerPage={rowsPerPage}
                page={page}
                style={{
                  color: '#5932EA',
                  fontSize: '12px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          }
        </div>
      </div>
      {/* Custom Date Range Modal */}
      <Dialog className='custom_date_modal p-0'
        open={openModal}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="content px-4 text-center">
                <h6 className='my-3 '>Custom filter!</h6>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 0.5, width: '15ch' },  // Reduced margin
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="date-from"
                      label="Date From"
                      type="date"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                      InputLabelProps={{
                        shrink: true,  // Ensures label doesn't overlap with date input
                      }}
                      sx={{
                        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                      }}
                    />
                    <TextField
                      id="date-to"
                      label="Date To"
                      type="date"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                      InputLabelProps={{
                        shrink: true,  // Ensures label doesn't overlap with date input
                      }}
                      sx={{
                        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                      }}
                    />
                  </div>
                </Box>
              </div>
              <button className="cancel m-1 py-1 px-2" onClick={() => { handleCloseModal(); setDateFrom(''); setDateTo(''); setFilterValue('') }}>Cancel</button>
              <button className="cancel m-1 py-1 px-2 active" onClick={handleCloseModal}>Filter</button>
            </div>
          </div>
        </div>

      </Dialog>

    </div>
  )
}
