import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer,TablePagination, TableHead, TableRow, Dialog, Box, Skeleton, Collapse,TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import HandleShopsHook from '../hooks/HandleShopsHook'
import { useAuthContext } from '../hooks/adminAuthHook'
import { updateShop } from '../redux/reducers/shopsSlice'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function Shops() {
  const adminProfile = useSelector((state) => state.adminProfile.adminProfile);


  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch()
  const { admin } = useAuthContext()
  const { loading, getShops } = HandleShopsHook()
  useEffect(() => {
    document.title = 'Shops';
    getShops()
  }, [])
  const shops = useSelector((state) => state.shops.shops)
  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState('')

  const[dateFrom,setDateFrom]=useState('')
  const[dateTo,setDateTo]=useState('')
  
  // Getting filtered registered shops
  const today = new Date();
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 7);

const isWithinFilter = (shops) => {
  const shopsCreatedAt = new Date(shops.createdAt);
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  // Ensure date strings are converted to Date objects and are valid
  const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

  if (filterValue === 'today') {
    return shopsCreatedAt.toDateString() === today.toDateString();
  } else if (filterValue === 'thisWeek') {
    return shopsCreatedAt >= sevenDaysAgo && shopsCreatedAt <= today;
  } else if (filterValue === 'custom') {
    // Handle empty strings gracefully
    const fromDate = dateFrom ? new Date(dateFrom) : undefined;
    const toDate = dateTo ? new Date(dateTo) : undefined;
    if (!isValidDate(fromDate) || !isValidDate(toDate)) {
    
      return false;
    }

    // Check if shopsCreatedAt is within the custom date range
    return shopsCreatedAt >= fromDate && shopsCreatedAt <= toDate;
  } else {
    return true; // 'all' case or any other default case
  }
};

const filteredShops = shops && shops
  .filter(shops => {
    const matchesSearch = (
      shops.name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      shops.email?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      shops.phone?.trim().includes(search.trim()) ||
      shops.customers?.length.toString().includes(search) ||
      shops.createdAt?.includes(search.trim()) ||
      shops.orders?.length.toString().includes(search) ||
      (shops.isActive ? 'active' : 'inactive').includes(search.trim().toLowerCase())
    );
    return matchesSearch && isWithinFilter(shops);
  })
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

// Finding the shops with the most total orders
// const maxOrdersShop = shops && shops.length > 0 && shops.reduce((max, shops) => {
//   const totalOrdersCount = shops.orders.length;
//   const maxTotalOrdersCount = max.orders ? max.orders.length : 0;
//   return (totalOrdersCount > maxTotalOrdersCount) ? shops : max;
// }, shops[0]);

  // Activate/Inactivate state
  const [selectedShop, setSelectedShop] = useState()
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (shops) => {
    setOpen(true);
    setSelectedShop(shops)

  };

  const handleClose = () => {
    setOpen(false);
    setSelectedShop('')
  }

  // Activating/Inactivating shops
  const [updateLoading, setUpdateLoading] = useState(null)
  const updateShopStatus = async () => {
    setUpdateLoading(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/shops/super/admin/shops/update/status`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ shopId: selectedShop && selectedShop._id })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        setOpen(false);
        setUpdateLoading(null)
        dispatch(updateShop(json.data))
      }
      if (!response.ok) {
        alert(json.message)
        setUpdateLoading(null)
      }
    } catch (error) {
      setUpdateLoading()
    }
  }

  // View Details
  const [openDetails, setOpenDetails] = useState(null);
  const toggleOpen = (shopId) => {
    setOpenDetails((prevAdminId) => (prevAdminId === shopId ? null : shopId));
  };

  // filter menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuStyles = {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
  };

  const menuItemStyles = {
    padding: '4px 12px',
    fontSize: '12px',
    color: '#000',
    cursor: 'pointer'
  };

  const menuItemHoverStyles = {
    backgroundColor: '#f5f5f5'
  };

  // custom date range filter
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = (shops) => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  }


  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const collapsed = useSelector((state) => state.collapsed.collapsed);

  return (
    <div className={`${collapsed ?"collapsed dashboard mt-5":"main dashboard mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-2">
          {loading && (shops && shops.length < 1) ?
            <>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </div>
            </>
            :
            <>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_received p-0 m-0">
                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                      </svg>
                        <h6 className='ms-2'>Total Shops</h6>
                      </div>
                      <div className="right">
                        <p>
                        {shops && (shops.length < 10 ? "0" + shops.length.toLocaleString() : shops.length.toLocaleString())}
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                      <p>Total number of registered shops</p>
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-md-6 col-lg-3 p-2">
                <div className="card top_shops">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                      </svg>

                        <h6 className='ms-2'>Paid Shops</h6>
                      </div>
                      <div className="right">
                        <p>
                        {shops && (shops.filter(shop=>shop.payment_Status.toLowerCase()==='paid').length < 10 ? "0" + shops.filter(shop=>shop.payment_Status.toLowerCase()==='paid').length : shops.filter(shop=>shop.payment_Status.toLowerCase()==='paid').length.toLocaleString())}
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Shop, which have purchased our packages</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_delivered">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2 ">
                      <div className=" left">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <h6 className='ms-2'>Active Shops</h6>
                      </div>
                      <div className="right">
                        <p>
                        {shops && (() => {
                          const activeShopsCount = shops.filter(shops => shops.isActive).length;
                          return activeShopsCount < 10 ? "0" + activeShopsCount.toLocaleString() : activeShopsCount.toLocaleString();
                        })()}
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Total number of Active shops</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 p-2">
                <div className="card inactive_shops">
                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <h6 className='ms-2'>Inactive Shops</h6>
                      </div>
                      <div className="right">
                        <p>
                        {shops && (() => {
                            const inactiveShopsCount = shops.filter(shops => !shops.isActive).length;
                            return inactiveShopsCount < 10 ? "0" + inactiveShopsCount.toLocaleString() : inactiveShopsCount.toLocaleString();
                          })()}

                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Total number of Inactive shops</p>
                    </div>
                  </div>
                </div>
              </div>
             
            </>
          }
        </div>

        <div className="row px-3 mt-md-4">
          {loading && (shops && shops.length < 1) ?
            <>

              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
            </>
            :
            <div className="col-md-12 data_table p-2 pb-5">
              <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                <h6>{filterValue === 'thisWeek' ? "This week new" : filterValue === 'today' ? "Today new" :filterValue === 'custom' ? "Custom date" : "All"} Shops <span>{dateFrom && dateTo &&`( ${dateFrom} to ${dateTo} )`}</span></h6>
                </div>
                <div className="d-flex right my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                  <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />

                  {/* filters */}
                  <button
                    className='filter_btn'
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleMenuClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>

                  </button>
                  <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    style: menuStyles,
                  }}
                >
                  <MenuItem
                    onClick={() => { handleMenuClose(); setFilterValue(''); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => { handleMenuClose(); setFilterValue('today'); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Today
                  </MenuItem>
                  <MenuItem
                    onClick={() => { handleMenuClose(); setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    This Week
                  </MenuItem>

                  <MenuItem
                    onClick={()=>{handleClickOpenModal();setFilterValue('custom')}}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Custom Date
                  </MenuItem>
                  <MenuItem
                    onClick={()=>{setFilterValue('');setDateFrom('');setDateTo('')}}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Default
                  </MenuItem>
                </Menu>
                </div>
              </div>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className='th text-center'>SN</TableCell>
                      <TableCell className='th text-center'>Date</TableCell>
                      <TableCell className='th text-center'>Shop Name</TableCell>
                      <TableCell className='th text-center'>Phone</TableCell>
                    <TableCell className='th text-center'>Referred By</TableCell>
                      <TableCell className='th text-center'>Revenue Earned</TableCell>
                      <TableCell className='th text-center'>Total Customers</TableCell>
                      <TableCell className='th text-center'>Total Orders</TableCell>
                      <TableCell className='th text-center'>Payment Status</TableCell>
                      <TableCell className='th text-center'>Package</TableCell>
                      <TableCell className='th text-center'>Expires in</TableCell>
                      <TableCell className='th text-center'>Status</TableCell>
                      {adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' &&
                        <TableCell className='th text-center'>Action</TableCell>
                      }
                      
                      <TableCell className='th text-center'>View</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredShops && filteredShops.length>0? filteredShops
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((shops, index) => (
                      <React.Fragment>
                        <TableRow key={index}>
                          <TableCell className='td text-center'>{index + 1}</TableCell>
                          <TableCell className='text-center td'>
                          {new Date(shops.createdAt).toLocaleDateString('en-GB').split('-').join('')}
                        </TableCell>
                          <TableCell className='td text-center'>{shops.name}</TableCell>
                          <TableCell className='td text-center'>{shops.phone}</TableCell>
                      <TableCell className='td text-center'>{shops.referral_Of?shops.referral_Of:'Direct'}</TableCell>
                          <TableCell className='td text-center '><span className='revenue_span p-1 rounded'>{shops.orders && shops.orders
                        .filter(order => order.order_Status.trim().toLowerCase() === 'delivered')
                        .reduce((sum, order) => sum + (order.paid_Amount || 0), 0).toLocaleString()} PKR</span></TableCell>
                          <TableCell className='td text-center'>{shops.customers.length}</TableCell>
                          <TableCell className='td text-center'>{shops.orders.length}</TableCell>
                      <TableCell className='td text-center'>{shops.payment_Status?.toLowerCase()==='paid' ? <span className="paid px-2 py-1">Paid</span> :shops.payment_Status?.toLowerCase()==='trial' ? <span className="trial px-2 py-1">Trial</span>: <span className="unpaid px-2 py-1">Unpaid</span>}</TableCell>
                      <TableCell className='td text-center'>{shops.package_Name}</TableCell>
                      <TableCell className='td text-center'>
                        {(() => {
                          if (shops.expiry_Date) {
                                const today = new Date();
                                const expiryDate = new Date(shops.expiry_Date);
                                const timeDiff = expiryDate - today;
                                const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                                return daysRemaining > 0
                                  ? `${daysRemaining} days`
                                  : 'Expired';
                              } else {
                                return 'No expiry date';
                              }
                            })()}
                          </TableCell>
                          
                          <TableCell className='td text-center'>{shops.isActive ? <span className="active_span px-2 py-1">Active</span> : <span className="inactive_span px-2 py-1">Inactive</span>}</TableCell>
                          {adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin'&&
                          <TableCell className='td text-center'><button onClick={() => handleClickOpen(shops)} className={shops.isActive ? "inactivate_shops py-1 px-1" : "activate_shops py-1 px-0"}>{shops.isActive ? "Inactivate" : "Activate"}</button></TableCell>
                          }
                          <TableCell className='text-center td'>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => toggleOpen(shops._id)}
                            >
                              {openDetails === shops._id ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>


                          </TableCell>
                        </TableRow>
                        {/* Orders Nested Details */}
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={14}>
                            <Collapse in={openDetails === shops._id} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }} >
                                <div className="d-flex justify-content-between searchbar pb-2 pt-3 px-1 my-auto">
                                  <div className="d-flex left my-auto">
                                    <h5>Orders details</h5>
                                  </div>
                                </div>
                                <Table aria-label="orders">
                                  <TableHead className='order_header'>
                                    <TableRow>
                                      <TableCell className='th text-center '>#</TableCell>
                                      <TableCell className='th text-center '>Order Date</TableCell>
                                      <TableCell className='th text-center '>Customers</TableCell>
                                      <TableCell className='th text-center '>Suits</TableCell>
                                      <TableCell className='th text-center '>Amount</TableCell>
                                      <TableCell className='th text-center '>Total</TableCell>
                                      <TableCell className='th text-center '>Paid</TableCell>
                                      <TableCell className='th text-center '>Delivery Date</TableCell>
                                      <TableCell className='th text-center '>Status</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {shops.orders.length > 0 ? shops.orders.map((order, index) => (
                                      <TableRow key={order._id}>
                                        <TableCell className='text-center td'>{index + 1}</TableCell>
                                        <TableCell className='text-center td'> {new Date(order.createdAt).toLocaleDateString('en-GB').split('-').join('')}</TableCell>
                                        <TableCell className='text-center td'>{order.customer_Name}</TableCell>
                                        <TableCell className='text-center td'>{order.total_Suites}</TableCell>
                                        <TableCell className='text-center td'>{order.amount_Per_Suite.toLocaleString()}</TableCell>
                                        <TableCell className='text-center td'>{(order.amount_Per_Suite*order.total_Suites).toLocaleString()}</TableCell>
                                        <TableCell className='text-center td'>{order.paid_Amount.toLocaleString()}</TableCell>
                                        <TableCell className='text-center td'>{new Date(order.delivery_Date).toLocaleDateString()}</TableCell>
                                        <TableCell className='text-center td'>{order.order_Status?.toLowerCase() === 'received' ? <span className="received_Status px-2 py-1">Received</span> : order.order_Status?.toLowerCase() === 'processing' ? <span className="process_Status px-2 py-1">Processing</span> : order.order_Status?.toLowerCase() === 'completed' ? <span className="completed_Status  px-2 py-1">Completed</span> : <span className="delivered_Status  px-2 py-1">Delivered</span>}</TableCell>
                                      </TableRow>
                                    )) :
                                      <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell className='td text-center '>Orders not found!</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    }
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>

                        {/* Customers Nested Details */}
                        <TableRow className='border-0'>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
                            <Collapse in={openDetails === shops._id} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <div className="d-flex justify-content-between searchbar pb-2 pt-3 px-1 my-auto">
                                  <div className="d-flex left my-auto">
                                    <h5>Customers details</h5>
                                  </div>
                                </div>
                                <Table aria-label="orders">
                                  <TableHead className='customer_header'>
                                    <TableRow>
                                      <TableCell className='th text-center '>#</TableCell>
                                      <TableCell className='th text-center '>Customers</TableCell>
                                      <TableCell className='th text-center '>Email</TableCell>
                                      <TableCell className='th text-center '>Phone</TableCell>
                                      <TableCell className='th text-center '>Gender</TableCell>
                                      <TableCell className='th text-center '>Total Orders</TableCell>
                                      <TableCell className='th text-center '>Received</TableCell>
                                      <TableCell className='th text-center '>Processing</TableCell>
                                      <TableCell className='th text-center '>Completed</TableCell>
                                      <TableCell className='th text-center '>Delivered</TableCell>
                                      <TableCell className='th text-center '>Address</TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {shops.customers.length > 0 ? shops.customers.map((customer, index) => (
                                      <TableRow key={customer._id}>
                                        <TableCell className='text-center td'>{index + 1}</TableCell>
                                        <TableCell className='td text-center '>{customer.customer_Name}</TableCell>
                                        <TableCell className='td text-center '>{customer.email}</TableCell>
                                        <TableCell className='td text-center '>{customer.phone}</TableCell>
                                        <TableCell className='td text-center '>{customer.gender}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.length}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.filter(order => order.order_Status.trim().toLowerCase() === 'received').length}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.filter(order => order.order_Status.trim().toLowerCase() === 'processing').length}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.filter(order => order.order_Status.trim().toLowerCase() === 'completed').length}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.filter(order => order.order_Status.trim().toLowerCase() === 'delivered').length}</TableCell>
                                        <TableCell className='td text-center '>{customer.address}</TableCell>
                                      </TableRow>
                                    )) :
                                      <TableRow>

                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        
                                        <TableCell className='td text-center '>Customers not found</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    }


                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )):
                    <TableRow className='border-0'>
                    <TableCell colSpan={6}></TableCell>
                    <TableCell className='td text-center'>No result</TableCell>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component='div'
                  count={filteredShops&&filteredShops.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  style={{
                    color: '#5932EA',
                    fontSize: '12px',
                    fontWeight: '700',
                    textTransform: 'capitalize',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
          }
        </div>
      </div>


      {/* Activate/Inactivate Modal */}
      <Dialog className='shops_modal p-0'
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="alert_icon text-center">
                <div className={selectedShop && !selectedShop.isActive ? "activate_icon  py-2" : "inactivate_icon  py-2"}>
                  {selectedShop && !selectedShop.isActive ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 ">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  }

                </div>
                <div className="content px-4">
                  <h6 className='mt-3'>Alert !</h6>
                  <p>Do you want to {selectedShop && !selectedShop.isActive ? "Activate" : "Inactivate"} {selectedShop && selectedShop.name}'s' account?</p>
                </div>
              </div>
              <button className="cancel m-1 py-1 px-2" disabled={updateLoading} onClick={handleClose}>Cancel</button>
              <button className={selectedShop && !selectedShop.isActive ? "activate m-1 py-1 px-2" : "inactivate m-1 py-1 px-2"} disabled={updateLoading} onClick={updateShopStatus}>{updateLoading ? "Saving" : selectedShop && !selectedShop.isActive ? "Activate" : "Inactivate"}</button>
            </div>
          </div>
        </div>

      </Dialog>

       {/* Custom Date Range Modal */}
       <Dialog className='custom_date_modal p-0'
        open={openModal}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
                <div className="content px-4 text-center">
                  <h6 className='my-3 '>Custom filter!</h6>
                  <Box
  component="form"
  sx={{
    '& .MuiTextField-root': { m: 0.5, width: '15ch' },  // Reduced margin
  }}
  noValidate
  autoComplete="off"
>
  <div>
    <TextField
      id="date-from"
      label="Date From"
      type="date"
      value={dateFrom}
      onChange={(e)=>setDateFrom(e.target.value)}
      InputLabelProps={{
        shrink: true,  // Ensures label doesn't overlap with date input
      }}
      sx={{
        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
      }}
    />
    <TextField
      id="date-to"
      label="Date To"
      type="date"
      value={dateTo}
      onChange={(e)=>setDateTo(e.target.value)}
      InputLabelProps={{
        shrink: true,  // Ensures label doesn't overlap with date input
      }}
      sx={{
        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
      }}
    />
  </div>
</Box>
                </div>
              <button className="cancel m-1 py-1 px-2" onClick={()=>{handleCloseModal();setDateFrom(''); setDateTo('');setFilterValue('')}}>Cancel</button>
              <button className="cancel m-1 py-1 px-2 active" onClick={handleCloseModal}>Filter</button>
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  )
}
