import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Dialog } from '@mui/material'
import { useSelector } from 'react-redux';
import AlertNotificationsHook from '../hooks/AlertNotificationsHook';
import { useAuthContext } from '../hooks/adminAuthHook';

export default function PaymentMethods() {
  const { admin } = useAuthContext();
  const apiUrl = process.env.REACT_APP_API_URL;
  const notifications = useSelector((state) => state.notifications.notifications);
  const { getAlertNotifications } = AlertNotificationsHook(); // Assuming updateTrial is a function in TrialsHook
  useEffect(() => {
    getAlertNotifications()
  }, [admin]);

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Notification Edition
  const [editedNotification, setEditedNotification] = useState(null)
  // Adding a new PaymentMethod
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [receivers, setReceivers] = useState("");
  const [sendNow, setSendNow] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isMail, setIsMail] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [image, setImage] = useState('');
  const [openNew, setOpenNew] = React.useState(false);
  const handleClickOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
    setTitle('')
    setDescription('')
    setReceivers('')
    setSendNow()
    setImage('')
    setIsNotification()
    setIsMail()
    setIsAlert()
    setEditedNotification('')
    setImage('')
  }

  const handleEditView = (data) => {
    setEditedNotification(data);
    setOpenNew(true);
    setTitle(data.title || "");
    setDescription(data.description || "");
    setReceivers(data.receivers || "");
    setImage(data.image || "");
    setIsNotification(data.isNotification);
    setIsMail(data.isMail);
    setIsAlert(data.isAlert);

  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    TransformFile(file)
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert('File size exceeds the 5MB limit. Please select a smaller file.');
      } else {
        TransformFile(file);
      }
    }
  };

  const TransformFile = (file) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    } else {
      setImage('');
    }
  };
  // Handle Select Input for Boolean Values
  const handleSelectChange = (setter) => (e) => {
    const value = e.target.value === "true" ? true : false;
    setter(value);
  };

  // API Call to add a new paymentMethod
  const [loading1, setLoading1] = useState(false)
  const addNewNotification = async (e) => {
    e.preventDefault()
    setLoading1(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/notifications/send`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ title, description, receivers, sendNow, isNotification, isMail, isAlert })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        getAlertNotifications()
        setLoading1(null)
        handleCloseNew()
      }
      if (!response.ok) {
        alert(json.message)
        setLoading1(null)
      }
    } catch (error) {
      setLoading1(null)
    }
  }

  //  Repeat Notifications
  const repeatSendNotification = async (e) => {
    e.preventDefault()
    setLoading1(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/notifications/repeat`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ notification: { _id: editedNotification._id, title, description, receivers, isNotification, isMail, isAlert,image } })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        getAlertNotifications()
        setLoading1(null)
        handleCloseNew()

      }
      if (!response.ok) {
        alert(json.message)
        setLoading1(null)
      }
    } catch (error) {
      setLoading1(null)
    }
  }
  const [search, setSearch] = useState('');
  const filteredNotifications = notifications && notifications
    .filter(notification => {
      const matchesSearch = (
        notification.title?.trim().toLowerCase().includes(search.trim().toLowerCase())
      );

      return matchesSearch
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const [viewSlip, setViewSlip] = useState('')
  const [viewModal, setViewModal] = useState(false)
  const handleViewSlip = (image) => {
    setViewSlip(image)
    setViewModal(true)
  }
  const handleCloseViewSlip = () => {
    setViewSlip('')
    setViewModal(false)
  }
  const collapsed = useSelector((state) => state.collapsed.collapsed);
  return (
    <div className={`${collapsed ? "collapsed dashboard mt-5" : "main dashboard mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">


        <div className='row px-2 mt-md-5'>
          <div className="col-md-12 data_table p-2 pb-5">
            <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
              <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                <h6>Push Notifications</h6>
              </div>
              <div className="d-flex right my-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                <button className='add_btn btn ms-1' onClick={() => handleClickOpenNew()}>New</button>
              </div>
            </div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                <TableHead>
                  <TableRow>
                    <TableCell className='th text-center'>SN</TableCell>
                    <TableCell className='th text-center'>Title </TableCell>
                    <TableCell className='th text-center'>Receivers</TableCell>
                    <TableCell className='th text-center'>Sent to</TableCell>
                    <TableCell className='th text-center'>Email</TableCell>
                    <TableCell className='th text-center'>Alert</TableCell>
                    <TableCell className='th text-center'>Notification</TableCell>
                    <TableCell className='th text-center'>Image</TableCell>
                    <TableCell className='th text-center'>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredNotifications && filteredNotifications.length > 0 ? filteredNotifications
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((notification, index) => (
                      <TableRow key={notification._id}>
                        <TableCell className='text-center td'>{index + 1}</TableCell>
                        <TableCell className='text-center td'>{notification.title}</TableCell>
                        <TableCell className='text-center td'>{notification.receivers}</TableCell>
                        <TableCell className='text-center td'>{notification.sent_To_Members} {notification.receivers}</TableCell>
                        <TableCell className='text-center td'>{notification.isMail ? <i className="fa-solid fa-circle-check text-success"></i> : <i class="fa-solid fa-circle-xmark text-danger"></i>}</TableCell>
                        <TableCell className='text-center td'>{notification.isAlert ? <i className="fa-solid fa-circle-check text-success"></i> : <i class="fa-solid fa-circle-xmark text-danger"></i>}</TableCell>
                        <TableCell className='text-center td'>{notification.isNotification ? <i className="fa-solid fa-circle-check text-success"></i> : <i class="fa-solid fa-circle-xmark text-danger"></i>}</TableCell>
                        <TableCell className='td text-center'>{notification.image ? <img onClick={() => handleViewSlip(notification.image)} src={notification.image} alt='Receipt' className='rounded' /> : "No Receipt"}</TableCell>
                        <TableCell className='td text-center'>
                          <div class="btn-group" role="group" aria-label="Basic example">

                            <button className="edit_btn py-1 px-2  m-1" onClick={() => handleEditView(notification)}>Repeat</button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )) :
                    <TableRow className='border-0'>
                      <TableCell colSpan={4}></TableCell>
                      <TableCell className='td text-center'>No result</TableCell>
                      <TableCell colSpan={4}></TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component='div'
              count={filteredNotifications && filteredNotifications.length}
              rowsPerPage={rowsPerPage}
              page={page}
              style={{
                color: '#5932EA',
                fontSize: '12px',
                fontWeight: '700',
                textTransform: 'capitalize',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          {/* View Payment Method image Modal */}
          <Dialog
            className="shops_modal p-0"
            open={viewModal}
            onClose={handleCloseViewSlip}
            keepMounted
            aria-describedby="alert-dialog-slide-description"

          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 text-center pb-2 px-0 m-0">
                  <div className="image-container">
                    {viewSlip && <img src={viewSlip} alt="View Slip" className="slip-image" />}
                  </div>
                </div>
              </div>
            </div>
          </Dialog>


          {/* adding a new Notification modal */}

          <Dialog className='p-0'
            open={openNew}
            fullWidth
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <div className="container-fluid">
              <div className="row ">
                <div className="col-md-12  pb-2 px-0 m-0">
                  <form onSubmit={editedNotification ? repeatSendNotification : addNewNotification}>
                    <div className="card add_new_package_card">
                      <div className="card-header">
                        {editedNotification ? "Resend Notification" : "Send a new Notification"}
                      </div>
                      <div className="card-body">

                        <div className="image-container text-center data my-1">
                          <div className="image-wrapper">
                            <img
                              src={image ? image : "https://via.placeholder.com/100"}
                              alt="team-member"
                              className="profile-img"
                              onClick={() => document.getElementById('fileInput').click()}
                            />
                            <span className="add-icon" onClick={() => document.getElementById('fileInput').click()}>
                              +
                            </span>
                          </div>
                          <input
                            type="file"
                            accept='image/*'
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleImage}
                          />
                        </div>
                        <div className="data my-1">
                          <label htmlFor="" >Title:</label>
                          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className='mt-1' id="" required />
                        </div>
                        <div className="data my-1">
                          <label htmlFor="" >Send to:</label>
                          <select name="" id="" value={receivers} onChange={(e) => setReceivers(e.target.value)} required>
                            <option value="">choose</option>
                            <option value="Shops">Shops</option>
                            <option value="Tailors">Tailors</option>
                          </select>
                        </div>
                        <div className="data my-1">
                          <label htmlFor="is-active">Send push notifications:</label>
                          <select
                            id="is-active"
                            required
                            value={isNotification}
                            onChange={handleSelectChange(setIsNotification)}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="data my-1">
                          <label htmlFor="is-active">Send alert notifications:</label>
                          <select
                            id="is-active"
                            required
                            value={isAlert}
                            onChange={handleSelectChange(setIsAlert)}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>

                        <div className="data my-1">
                          <label htmlFor="is-active">Send emails:</label>
                          <select
                            id="is-active"
                            required
                            value={isMail}
                            onChange={handleSelectChange(setIsMail)}
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        {!editedNotification &&
                          <div className="data my-1">
                            <label htmlFor="is-active">Send now?</label>
                            <select
                              id="is-active"
                              required
                              value={sendNow}
                              onChange={handleSelectChange(setSendNow)}
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        }
                        <div className="data my-1">
                          <label htmlFor="is-active">Description:</label>
                          <textarea name="" id="" value={description} onChange={(e) => setDescription(e.target.value)}>

                          </textarea>
                        </div>
                        <div className="text-end mt-3">
                          <button className="btn cancel_btn mx-1" type='button' onClick={() => handleCloseNew()} disabled={loading1}>Cancel</button>
                          <button className="btn save_btn mx-1" type='submit' disabled={loading1}>{loading1 ? "Saving" : "Save"}</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Dialog>


        </div>
      </div>
    </div>
  )
}
