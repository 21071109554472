import React,{useState,useEffect} from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow,Collapse,Box } from '@mui/material'
import { useSelector } from 'react-redux';
import ReferralsHook from '../hooks/ReferralsHook';
import { useAuthContext } from '../hooks/adminAuthHook';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function AllReferrals() {
  const { admin } = useAuthContext();
  const referrals = useSelector((state) => state.referrals.referrals);
  const { getReferrals } = ReferralsHook(); 
  useEffect(() => {
    getReferrals()
  }, [admin]);

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
   const [search, setSearch] = useState('');
   const filteredReferrals = referrals && referrals
     .filter(referral => {
       const matchesSearch = (
         referral.phone?.trim().toLowerCase().includes(search.trim().toLowerCase())
       );
 
       return matchesSearch
     })
     .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
     

     // View Details
    const [openDetails, setOpenDetails] = useState(null);
    const toggleOpen = (shopId) => {
        setOpenDetails((prevId) => (prevId === shopId ? null : shopId));
    };


  return (
    <div className='row px-2'>
 <div className="col-md-12 data_table p-2 pb-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                      <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                        <h6>All Referrals</h6>
                      </div>
                      <div className="d-flex right my-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                      </div>
                    </div>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                        <TableHead>
                          <TableRow>
                            <TableCell className='th text-center'>SN</TableCell>
                            <TableCell className='th text-center'>Referrals</TableCell>
                            <TableCell className='th text-center'>Total References</TableCell>
                            <TableCell className='th text-center'>Total Balance</TableCell>
                            <TableCell className='th text-center'>Paid Amount</TableCell>
                            <TableCell className='th text-center'>Remaining Balance</TableCell>
                            <TableCell className='th text-center'>Pending Withdraws</TableCell>
                            <TableCell className='th text-center'>View</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredReferrals && filteredReferrals.length > 0 ? filteredReferrals
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((referral, index) => (
                              <>
                              <TableRow key={referral._id}>
                                <TableCell className='text-center td'>{index + 1}</TableCell>
                                <TableCell className='text-center td'>{referral.phone}</TableCell>
                                <TableCell className='text-center td'><span className='badge bg-primary text-white px-2 py-1'>{referral.totalReferrals}</span></TableCell>
                                <TableCell className='text-center td'><span className='badge bg-warning text-white px-2 py-1'>{referral.totalBalance.toLocaleString()} PKR</span></TableCell>
                                <TableCell className='text-center td'><span className='badge bg-success text-white px-2 py-1'>{referral.totalPaidAmount.toLocaleString()} PKR</span></TableCell>
                                <TableCell className='text-center td'><span className='badge bg-info text-white px-2 py-1'>{referral.remainingBalance.toLocaleString()} PKR</span></TableCell>
                                <TableCell className='text-center td'><span className='badge bg-danger text-white px-2 py-1'>{referral.totalPendingWithdrawals.toLocaleString()} PKR</span></TableCell>
                                <TableCell className='text-center td'>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => toggleOpen(referral._id)}
                                                    >
                                                        {openDetails === referral._id ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )}
                                                    </IconButton>


                                                </TableCell>
                                </TableRow>
                                {/* Nested Details */}
                                <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={12}>
                                    <Collapse in={openDetails === referral._id} timeout="auto" unmountOnExit>
                                        <Box sx={{ margin: 1 }} >
                                            <div className="d-flex justify-content-between searchbar pb-2 pt-3 px-1 my-auto">
                                                <div className="d-flex left my-auto">
                                                    <h5>Referred Accounts</h5>
                                                </div>
                                            </div>
                                            <Table aria-label="orders">
                                                <TableHead className='order_header'>
                                                    <TableRow>
                                                        <TableCell className='th text-center '>#</TableCell>
                                                        <TableCell className='th text-center '>Shops</TableCell>
                                                        <TableCell className='th text-center '>Phone</TableCell>
                                                        <TableCell className='th text-center '>Commission</TableCell>
                                                        <TableCell className='th text-center '>Commission Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {referral.referredAccounts?.length > 0 ? referral.referredAccounts?.map((account, index) => (
                                                        <TableRow key={account._id}>
                                                            <TableCell className='text-center td'>{index + 1}</TableCell>
                                                            <TableCell className='text-center td'>{account.referredShopName}</TableCell>
                                                            <TableCell className='text-center td'>{account.referredShopPhone}</TableCell>
                                                            <TableCell className='text-center td'>{account.referrence_Reward} PKR</TableCell>
                                                            <TableCell className='text-center td'><span className={`${account.isRewarded ? 'badge bg-success text-white p-1' : "badge bg-danger text-white px-1 py-2"}`}>{account.isRewarded ? "Rewarded" : "Pending"}</span></TableCell>

                                                        </TableRow>
                                                    )) :
                                                        <TableRow>
                                                        <TableCell colSpan={3}></TableCell>
                                                        <TableCell className='td text-center'>No result</TableCell>
                                                        <TableCell colSpan={3}></TableCell> 
                                                        </TableRow>
                                                    }


                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                            </>
                            )) :
                            <TableRow className='border-0'>
                              <TableCell colSpan={3}></TableCell>
                              <TableCell className='td text-center'>No result</TableCell>
                              <TableCell colSpan={4}></TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions}
                      component='div'
                      count={filteredReferrals && filteredReferrals.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      style={{
                        color: '#5932EA',
                        fontSize: '12px',
                        fontWeight: '700',
                        textTransform: 'capitalize',
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                  </div>
  )
}
