import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Dialog, Box, Skeleton, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import OffersHook from '../hooks/OffersHook'
import HandleShopsHook from '../hooks/HandleShopsHook'
import { useAuthContext } from '../hooks/adminAuthHook'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function Offers() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { admin } = useAuthContext()
  const { loading, getOffers } = OffersHook()
  const { getShops } = HandleShopsHook()
  useEffect(() => {
    document.title = 'Offers';
    getOffers()
    getShops()
  }, [admin])
  
  const offers = useSelector((state) => state.offers.offers)
  const shops = useSelector((state) => state.shops.shops)

  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState('')

  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const isWithinFilter = (offer) => {
    const offerCreatedAt = new Date(offer.createdAt);

    // Ensure date strings are converted to Date objects and are valid
    const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

    if (filterValue === 'today') {
      return offerCreatedAt.toDateString() === today.toDateString();
    } else if (filterValue === 'thisWeek') {
      return offerCreatedAt >= sevenDaysAgo && offerCreatedAt <= today;
    } else if (filterValue === 'custom') {
      // Handle empty strings gracefully
      const fromDate = dateFrom ? new Date(dateFrom) : null;
      const toDate = dateTo ? new Date(dateTo) : null;

      // Ensure both dates are valid
      if ((!fromDate || !isValidDate(fromDate)) || (!toDate || !isValidDate(toDate))) {
        return false; // Invalid or missing date
      }

      // Check if offerCreatedAt is within the custom date range
      return offerCreatedAt >= fromDate && offerCreatedAt <= toDate;
    } else {
      return true; // 'all' case or any other default case
    }
  };

  const filteredOffers = offers && offers
    .filter(offer => {
      const matchesSearch = (
        offer.name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        offer.email?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        offer.phone?.trim().toLowerCase().includes(search.trim().toLowerCase())
      );


      // Only include offers that match the search criteria, status filter, and are within the filter range
      return matchesSearch && isWithinFilter(offer);
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


  const [shopId, setShopId] = useState('')
  const [days, setDays] = useState()
  const [price, setPrice] = useState()

    // Filter active shops based on the search term
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
  
    // Filter active shops based on the search term
    const filteredShops = shops.filter(
      (data) =>
        data.isActive &&
        data.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Handle shop selection
    const handleShopSelection = (shop) => {
      setShopId(shop._id);
      setIsDropdownOpen(false); // Close the dropdown when a shop is selected
    };



  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setShopId('')
    setDays()
    setPrice()
    setSearchTerm('')
    setIsDropdownOpen(false);
  }
  const [isLoading, setIsLoading] = useState(false)
  const giveNewOffer = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/offers/add`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ shopId, price, days })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        setOpen(false);
        setIsLoading(null)
        getOffers()
      }
      if (!response.ok) {
        alert(json.message)
        setIsLoading(null)
      }
    } catch (error) {
      setIsLoading(null)
    }
  }


  // filter menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuStyles = {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
  };

  const menuItemStyles = {
    padding: '4px 12px',
    fontSize: '12px',
    color: '#000',
    cursor: 'pointer'
  };

  const menuItemHoverStyles = {
    backgroundColor: '#f5f5f5'
  };

  // custom date range filter
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = (offers) => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  }

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const collapsed = useSelector((state) => state.collapsed.collapsed);

  return (
    <div className={`${collapsed ? "collapsed dashboard mt-5" : "main dashboard mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-3 mt-md-5">
          {loading && (offers && offers.length < 1) ?
            <>
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
            </>
            :
            <div className="col-md-12 data_table p-2 pb-5">
              <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                  <h6>{filterValue === 'thisWeek' ? "This week" : filterValue === 'today' ? "Today" : filterValue === 'custom' ? "Custom date" : "All"} Offers <span>{dateFrom && dateTo && `( ${dateFrom} to ${dateTo} )`}</span></h6>
                </div>
                <div className="d-flex right my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                  <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                  <button
                    className='filter_btn'
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleMenuClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>

                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                      style: menuStyles,
                    }}
                  >
                    <MenuItem
                      onClick={() => { handleMenuClose(); setFilterValue(''); setDateFrom(''); setDateTo('') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      onClick={() => { handleMenuClose(); setFilterValue('today'); setDateFrom(''); setDateTo('') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      Today
                    </MenuItem>
                    <MenuItem
                      onClick={() => { handleMenuClose(); setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      This Week
                    </MenuItem>
                    <MenuItem
                      onClick={() => { handleClickOpenModal(); setFilterValue('custom') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      Custom Date
                    </MenuItem>
                    <MenuItem
                      onClick={() => { setFilterValue(''); setDateFrom(''); setDateTo('') }}
                      style={menuItemStyles}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                      Default
                    </MenuItem>
                  </Menu>
                  <button className='add_btn btn ms-1' onClick={() => handleClickOpen()}>Add</button>

                </div>
              </div>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className='th text-center'>SN</TableCell>
                      <TableCell className='th text-center'>Date</TableCell>
                      <TableCell className='th text-center'>Shop Name</TableCell>
                      <TableCell className='th text-center'>Email</TableCell>
                      <TableCell className='th text-center'>Phone</TableCell>
                      <TableCell className='th text-center'>Address</TableCell>
                      <TableCell className='th text-center'>Days</TableCell>
                      <TableCell className='th text-center'>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredOffers && filteredOffers.length > 0 ? filteredOffers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((offers, index) => (
                        <React.Fragment>
                          <TableRow key={index}>
                            <TableCell className='td text-center'>{index + 1}</TableCell>
                            <TableCell className='text-center td'>
                              {new Date(offers.createdAt).toLocaleDateString('en-GB').split('-').join('')}
                            </TableCell>
                            <TableCell className='td text-center'>{offers.name}</TableCell>
                            <TableCell className='td text-center'>{offers.email}</TableCell>
                            <TableCell className='td text-center'>{offers.phone}</TableCell>
                            <TableCell className='td text-center'>{offers.address}</TableCell>
                            <TableCell className='td text-center'>{offers?.days}</TableCell>
                            <TableCell className='td text-center'>{offers?.price.toLocaleString()}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      )) :
                      <TableRow className='border-0'>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell className='td text-center'>No result</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                count={filteredOffers && filteredOffers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                style={{
                  color: '#5932EA',
                  fontSize: '12px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          }
        </div>
      </div>


      {/* Giving offers Modal */}
      <Dialog className='shops_modal p-0'
        open={open}
        onClose={handleClose}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <form onSubmit={giveNewOffer}>

                <div className="alert_icon text-center">
                  <div className="activate_icon  py-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                    </svg>

                  </div>
                  <div className="content px-4">
                    <h6 className='mt-3'>Give Offer !</h6>
                    <p>Add price and duration to give a offer to a shop!</p>
                    {/* <select className='mb-3' name="" id="" value={shopId} onChange={(e) => setShopId(e.target.value)} required>
                      <option value="">Select shop</option>
                      {shops && shops.filter(data => data.isActive).map((data) => (
                        <option value={data._id} key={data._id}>{data.name} ({data.phone})</option>
                      ))}
                    </select> */}

<div className="custom-shop-selector position-relative">
      {/* Select box that shows the selected shop */}
      <div
        className="form-control mb-3 text-start"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        style={{ cursor: 'pointer' }}
      >
        {shopId
          ? shops.find((shop) => shop._id === shopId)?.name || 'Select Shop'
          : 'Select shop'}
      </div>

      {/* Dropdown with search input and shop options */}
      {isDropdownOpen && (
        <div className="dropdown-menu show w-100 py-0 mb-0 "  style={{ zIndex: 10 }}>
          {/* Search input */}
          <input
            type="text"
            placeholder="Search shops..."
            className=" form-control mt-0"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
          />

          {/* Shop options */}
          <ul className="list-group mt-0 pt-0">
            {filteredShops.length > 0 ? (
              filteredShops.map((shop) => (
                <li
                  key={shop._id}
                  className="list-group-item list-group-item-action mt-0 "
                  onClick={() => handleShopSelection(shop)}
                  style={{ cursor: 'pointer' }}
                >
                  {shop.name} ({shop.phone})
                </li>
              ))
            ) : (
              <li className="list-group-item">No shops found</li>
            )}
          </ul>
        </div>
      )}
    </div>
                    <input type="number" className='mb-3' placeholder='Offer days' value={days} onChange={(e) => setDays(parseInt(e.target.value, 10))} required />
                    <input type="number" className='mb-3' placeholder='Offer Price' value={price} onChange={(e) => setPrice(parseInt(e.target.value, 10))} required />
                  </div>
                </div>
                <button className="cancel m-1 py-1 px-2" type='button' disabled={isLoading} onClick={handleClose}>Cancel</button>
                <button className="activate m-1 py-1 px-2" type='submit' disabled={isLoading} >{isLoading ? "Saving" : "Save"}</button>
              </form>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Custom Date Range Modal */}
      <Dialog className='custom_date_modal p-0'
        open={openModal}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="content px-4 text-center">
                <h6 className='my-3 '>Custom filter!</h6>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 0.5, width: '15ch' },  // Reduced margin
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="date-from"
                      label="Date From"
                      type="date"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                      InputLabelProps={{
                        shrink: true,  // Ensures label doesn't overlap with date input
                      }}
                      sx={{
                        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                      }}
                    />
                    <TextField
                      id="date-to"
                      label="Date To"
                      type="date"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                      InputLabelProps={{
                        shrink: true,  // Ensures label doesn't overlap with date input
                      }}
                      sx={{
                        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                      }}
                    />
                  </div>
                </Box>
              </div>
              <button className="cancel m-1 py-1 px-2" onClick={() => { handleCloseModal(); setDateFrom(''); setDateTo(''); setFilterValue('') }}>Cancel</button>
              <button className="cancel m-1 py-1 px-2 active" onClick={handleCloseModal}>Filter</button>
            </div>
          </div>
        </div>

      </Dialog>

    </div>
  )
}
