import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer,TablePagination, TableHead, TableRow, Menu, MenuItem, Skeleton, Box, Dialog,TextField  } from '@mui/material'
import { useSelector } from 'react-redux';
import HandleShopsHook from '../hooks/HandleShopsHook'
import CustomersHook from '../hooks/CustomersHook'
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow'

export default function Dashbaord() {
  const { loading, getShops } = HandleShopsHook()
  const { getCustomers } = CustomersHook()
  useEffect(() => {
    document.title = 'Dashboard';
    getShops()
    getCustomers()
  }, [])

  const customers = useSelector((state) => state.customers.customers)
  const shops = useSelector((state) => state.shops.shops)
  const collapsed = useSelector((state) => state.collapsed.collapsed);


  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState('thisWeek')

  const[dateFrom,setDateFrom]=useState('')
  const[dateTo,setDateTo]=useState('')
  
  // Getting filtered registered shops
  const today = new Date();
    // Define options for formatting the date
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
  
    // Get the formatted date string
    const formattedDate = today.toLocaleDateString('en-US', options);

 const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

const isWithinFilter = (shop) => {
  const shopCreatedAt = new Date(shop.createdAt);
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  // Ensure date strings are converted to Date objects and are valid
  const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

  if (filterValue === 'today') {
    return shopCreatedAt.toDateString() === today.toDateString();
  } else if (filterValue === 'thisWeek') {
    return shopCreatedAt >= sevenDaysAgo && shopCreatedAt <= today;
  } else if (filterValue === 'custom') {
    // Handle empty strings gracefully
    const fromDate = dateFrom ? new Date(dateFrom) : undefined;
    const toDate = dateTo ? new Date(dateTo) : undefined;
    if (!isValidDate(fromDate) || !isValidDate(toDate)) {
      
      return false;
    }

    // Check if shopCreatedAt is within the custom date range
    return shopCreatedAt >= fromDate && shopCreatedAt <= toDate;
  } else {
    return true; // 'all' case or any other default case
  }
};

const filteredShops = shops && shops
  .filter(shop => {
    const matchesSearch = (
      shop.name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      shop.email?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      shop.phone?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      shop.customers.length.toString().includes(search.toLowerCase()) ||
      shop.createdAt.includes(search.trim()) ||
      shop.orders.length.toString().includes(search.toLowerCase()) ||
      (shop.isActive ? 'active' : 'inactive').includes(search.trim().toLowerCase())
    );

    return matchesSearch && isWithinFilter(shop);
  })
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const totalOrders = shops.reduce((total, shop) => total + (shop.orders ? shop.orders.length : 0), 0);

  // Total revenue earned by each driver
  const totalRevenue = shops && shops.reduce((total, shop) => {
    if (!shop.orders) return total;
  
    const deliveredOrders = shop.orders.filter(order => order.order_Status.trim().toLowerCase() === 'delivered');
    const revenue = deliveredOrders.reduce((sum, order) => {
      return sum + (order.paid_Amount || 0);
    }, 0);
  
    return total + revenue;
  }, 0);
  
 
  // filter menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuStyles = {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
  };

  const menuItemStyles = {
    padding: '4px 12px',
    fontSize: '12px',
    color: '#000',
    cursor: 'pointer'
  };

  const menuItemHoverStyles = {
    backgroundColor: '#f5f5f5'
  };

  // custom date range filter
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  }

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className={`${collapsed ?"collapsed dashboard mt-5 ":"main dashboard mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-2">
          {loading && (shops && shops.length < 1) ?
            <>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  <Skeleton animation="wave" />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  <Skeleton animation="wave" />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  <Skeleton animation="wave" />
                </Box>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <Box className='p-0 m-0'>
                <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton  />
                  <Skeleton animation="wave" />
                </Box>
              </div>
            </> :
            <>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_received p-0 m-0">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                      </svg>
                        <h6 className='ms-2'>Total Shops</h6>
                      </div>
                      <div className="right">
                        <p>
                          {shops &&( shops.length < 10 && "0") + shops.length}
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                      <p>Updated at {formattedDate}</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_processing">
                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                        </svg>

                        <h6 className='ms-2'>Total Customers</h6>
                      </div>
                      <div className="right">
                      <p>
                      {customers &&( customers.length < 10 && "0") + customers.length}
                    </p>

                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Updated at {formattedDate}</p>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_completed">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                        </svg>
                        
                        <h6 className='ms-2'>Total Orders</h6>
                      </div>
                      <div className="right">
                      <p>
                      {totalOrders && (totalOrders < 10 ? "0" + totalOrders : totalOrders)}
                    </p>

                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Updated at {formattedDate}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_delivered">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>

                        <h6 className='ms-2'>Shops Revenue</h6>
                      </div>
                      <div className="right">
                        <p>
                        {(totalRevenue !== undefined && (totalRevenue < 10 ? "0" + totalRevenue : totalRevenue)).toLocaleString()} PKR
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>Updated at {formattedDate}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

        </div>

        {/* Table details */}
        <div className="row px-3 mt-md-4">
          {loading && (shops && shops.length < 1)?
           <>

           <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
           <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
           <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
           <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
       </>
       :
       <div className="col-md-12 data_table p-2 pb-5">
            <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
    {/* Left section */}
    <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
      <h6>
        {filterValue === 'thisWeek' ? "This week new" : filterValue === 'today' ? "Today new" : filterValue === 'custom' ? "Custom date" : "All"} Shops{" "}
        <span>{dateFrom && dateTo && `( ${dateFrom} to ${dateTo} )`}</span>
      </h6>
    </div>

    {/* Right section */}
    <div className="d-flex right my-auto">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
      </svg>
      <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search.." />

      {/* Filters button */}
      <button className="filter_btn" id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
        </svg>
      </button>

      {/* Menu for filter options */}
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button', style: menuStyles }}>
        <MenuItem onClick={() => { handleClose(); setFilterValue(''); setDateFrom(''); setDateTo('') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
          All
        </MenuItem>
        <MenuItem onClick={() => { handleClose(); setFilterValue('today'); setDateFrom(''); setDateTo('') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
          Today
        </MenuItem>
        <MenuItem onClick={() => { handleClose(); setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
          This Week
        </MenuItem>
        <MenuItem onClick={() => { handleClickOpenModal(); setFilterValue('custom') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
          Custom Date
        </MenuItem>
        <MenuItem onClick={() => { setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
          Default
        </MenuItem>
      </Menu>
    </div>
  </div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                <TableHead>
                  <TableRow>
                    <TableCell className='th text-center'>SN</TableCell>
                    <TableCell className='th text-center'>Date/Time</TableCell>
                    <TableCell className='th text-center'>Shops</TableCell>
                    <TableCell className='th text-center'>Phone</TableCell>
                    <TableCell className='th text-center'>Referred By</TableCell>
                    <TableCell className='th text-center'>Revenue Earned</TableCell>
                    <TableCell className='th text-center'>Total Customers</TableCell>
                    <TableCell className='th text-center'>Total Orders</TableCell>
                    <TableCell className='th text-center'>Payment Status</TableCell>
                    <TableCell className='th text-center'>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredShops && filteredShops.length>0? filteredShops
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((shop, index) => (
                    <TableRow key={index}>
                      <TableCell className='td text-center'>{index + 1}</TableCell>
                      <TableCell className='td text-center'>{formatDistanceToNow(new Date(shop.createdAt), { addSuffix: true })}</TableCell>
                      <TableCell className='td text-center'>{shop.name}</TableCell>
                      <TableCell className='td text-center'>{shop.phone}</TableCell>
                      <TableCell className='td text-center'>{shop.referral_Of?shop.referral_Of:'Direct'}</TableCell>
                      <TableCell className='td text-center '><span className='revenue_span p-1 rounded'>{shop.orders && shop.orders
                        .filter(order => order.order_Status?.trim().toLowerCase() === 'delivered')
                        .reduce((sum, order) => sum + (order.paid_Amount || 0), 0).toLocaleString()} PKR</span></TableCell>
                      <TableCell className='td text-center'>{shop.customers.length}</TableCell>
                      <TableCell className='td text-center'>{shop.orders.length}</TableCell>
                      <TableCell className='td text-center'>{shop.payment_Status?.toLowerCase()==='paid' ? <span className="paid px-2 py-1">Paid</span> :shop.payment_Status?.toLowerCase()==='trial' ? <span className="trial px-2 py-1">Trial</span>: <span className="unpaid px-2 py-1">Unpaid</span>}</TableCell>
                      <TableCell className='td text-center'>{shop.isActive ? <span className="active px-2 py-1">Active</span> : <span className="unactive px-2 py-1">Inactive</span>}</TableCell>
                      
                    </TableRow>
                  )):
                  <TableRow className='border-0'>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className='td text-center'>No result</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component='div'
                  count={filteredShops&&filteredShops.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  style={{
                    color: '#5932EA',
                    fontSize: '12px',
                    fontWeight: '700',
                    textTransform: 'capitalize',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
          </div>

           }
          
        </div>
      </div>

      {/* Custom Date Range Modal */}
      <Dialog className='custom_date_modal p-0'
        open={openModal}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
                <div className="content px-4 text-center">
                  <h6 className='my-3 '>Custom filter!</h6>
                  <Box
  component="form"
  sx={{
    '& .MuiTextField-root': { m: 0.5, width: '15ch' },  // Reduced margin
  }}
  noValidate
  autoComplete="off"
>
  <div>
    <TextField
      id="date-from"
      label="Date From"
      type="date"
      value={dateFrom}
      onChange={(e)=>setDateFrom(e.target.value)}
      InputLabelProps={{
        shrink: true,  // Ensures label doesn't overlap with date input
      }}
      sx={{
        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
      }}
    />
    <TextField
      id="date-to"
      label="Date To"
      type="date"
      value={dateTo}
      onChange={(e)=>setDateTo(e.target.value)}
      InputLabelProps={{
        shrink: true,  // Ensures label doesn't overlap with date input
      }}
      sx={{
        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
      }}
    />
  </div>
</Box>
                </div>
              <button className="cancel m-1 py-1 px-2" onClick={()=>{handleCloseModal();setDateFrom(''); setDateTo('');setFilterValue('')}}>Cancel</button>
              <button className="cancel m-1 py-1 px-2 active" onClick={handleCloseModal}>Filter</button>
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  )
}
